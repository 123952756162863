<template>
  <div>
    <div>
      <router-view> </router-view>
    </div>
    <notifications position="top right" classes="vue-notification mt-3"></notifications>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>