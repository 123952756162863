<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto d-flex align-items-center mb-4">
            <a
              class="pr-2 text-dark cursor-pointer"
              v-on:click="$router.push('/category')"
              ><span class="font-24 mr-2"
                ><img src="/images/back-arrow.svg" width="18"
              /></span>
            </a>
            <h4 class="mb-0 lg-bld">Manage Category</h4>
          </div>
        </div>
        <div
          class="page-content"
          v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage"
        >
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 10px"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(currentPage)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                  />
                </div>
                <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getList(currentPage)"
                  >Search</a
                >
                <a
                  class="fill-btn btn-style cursor-pointer"
                  v-on:click="resetFilter"
                  v-show="searchObj.keyword"
                  >Reset</a
                >
              </div>
              <div
                v-show="accessObject?.is_add == 1"
                style="cursor: pointer"
                class="col-lg-6 col-sm-6 txt-right form-group"
              >
                <a
                  class="fill-btn cursor-pointer"
                  style="padding: 11px; margin-right: 16px"
                  v-on:click="showAddSubCategoryPopup"
                  >+ Add</a
                >
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th>Image</th>
                          <th
                            class="sorting"
                            id="id-name"
                            v-on:click="sorting('name', 'id-name')"
                          >
                            Name
                          </th>
                          <th
                            v-show="
                              accessObject?.is_edit == 1 || accessObject?.is_view == 1
                            "
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td style="text-align: left">
                            {{  (currentPage - 1) * itemsPerPage + index + 1 }}
                          </td>
                          <td>
                            <img
                              :src="data.image ? data.image : '/images/placeholder.gif'"
                              width="40"
                              height="40"
                              style="border-radius: 100px"
                            />
                          </td>
                          <td style="text-align: left">{{ data.name }}</td>
                          <td
                            v-show="
                              accessObject?.is_edit == 1 || accessObject?.is_view == 1
                            "
                          >
                            <a
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="$router.push('/sub-sub-category/' + data.id)"
                              >Sub Categories</a
                            >
                            <a
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="
                                $router.push('/product_attribute/category/' + data.id)
                              "
                              >Product Attributes</a
                            >
                            <a
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="showUpdateSubCategoryPopup(data)"
                              v-show="accessObject?.is_edit == 1"
                              >Edit</a
                            >
                            <a
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="showDeletePopup(data)"
                              v-show="accessObject?.is_delete == 1"
                              >Delete</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList()" ref="errorComponent" />
                <TextModal ref="textModal">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      {{ details.id ? "Edit" : "Add" }} Category
                    </h5>
                    <button
                      type="button"
                      class="close"
                      v-on:click="$refs.textModal.closeModal"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <Form ref="subCategoryForm" @submit="save()">
                    <div class="modal-body" style="overflow-y: scroll; height: 500px">
                      <div class="row">
                        <div class="col-lg-12 col-sm-12 mb-3">
                          <div class="text-center mb-4">
                            <img
                              :src="
                                details.image && details.image != null
                                  ? details.image
                                  : '/images/placeholder.gif'
                              "
                              id="preview"
                              class="img-thumbnail"
                              style="border-radius: 100px; width: 120px; height: 120px"
                            /><br />
                            <div id="msg"></div>
                            <div id="image-form" style="margin: 0 auto; width: 90px">
                              <input
                                type="file"
                                name="img[]"
                                class="file"
                                id="pickFile"
                                @change="onChange($event)"
                                accept="image/*"
                                style="display: none"
                              />
                              <div class="input-group my-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  disabled
                                  placeholder="Upload File"
                                  id="file"
                                  style="display: none"
                                />
                                <div class="changePic" style="bottom: -25px">
                                  <label for="pickFile"> Change</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-sm-12">
                          <div class="form-group">
                            <label> Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              class="form-control"
                              name="name"
                              v-model="details.name"
                              placeholder="Sub Category Name"
                              rules="required:name"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="name" class="text-danger" />
                          </div>
                        </div>

                        <div
                          class="col-lg-6 col-sm-6"
                          v-for="(data, index) in languageList"
                          :key="index"
                        >
                          <div class="form-group">
                            <label
                              >Category Name ({{ data.code }}
                              <img
                                :src="data.flag ? data.flag : '/img/placeholder.png'"
                                style="width: 20px; height: 17px"
                              />)</label
                            >
                            <Field
                              type="text"
                              class="form-control"
                              :name="'name_' + data.code"
                              v-model="data.value"
                              :placeholder="'Category Name (' + data.code + ')'"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              :name="'name_' + data.code"
                              class="text-danger"
                            />
                          </div>
                          <!-- :rules="'required:Category Name (' + data.code + ')'" -->
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button class="fill-btn" id="save-button">Save</button>
                      <button
                        type="button"
                        class="fil-btn"
                        id="cancel-button"
                        v-on:click="$refs.textModal.closeModal"
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                </TextModal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
    <TextModal ref="editModal">
      <div class="modal-header">
        <h5 class="modal-title">Select Language</h5>
        <button type="button" class="close" v-on:click="$refs.editModal.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form @submit="editProduct">
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-sm-12 mb-3">
              <div class="form-group col-md-6">
                <label for="inputRole">Language</label>
                <span class="text-danger">*</span>
                <Field
                  as="select"
                  class="form-control"
                  id="inputRole"
                  name="name_language"
                  v-model="editLangCode"
                  rules="required:language"
                >
                  <option value="">Select Language</option>
                  <option
                    v-for="(data, index) in languageList"
                    :key="index"
                    :value="data.code"
                  >
                    {{ data.title }} ({{ data.code }})
                  </option>
                </Field>
                <ErrorMessage name="name_language" class="text-danger" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="fill-btn" id="save-button">Go</button>
          <button
            type="button"
            class="fil-btn"
            id="cancel-button"
            v-on:click="$refs.editModal.closeModal"
          >
            Cancel
          </button>
        </div>
      </Form>
    </TextModal>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import TextModal from "@/components/TextModal";

import Pagination from "@/components/Pagination";
export default {
  name: "SubCategory",
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
    DeleteModal,
    TextModal,
    Pagination,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage Categories"),
      isShowAccessDeniedMessage: false,
      id: this.$route.params.id,
      loader: true,
      offset: -1,
      itemsPerPage: 10,
      currentPage:1,
      searchObj: {
        keyword: "",
      },
      details: {},
      list: [],
      languageList: [],
    };
  },
  mounted() {
    if (!this.accessObject) {
      this.isShowAccessDeniedMessage = true;
      this.$router.push("/access-denied");
      return;
    }
    this.getlanguageList();
    var tabChangePage = this.$shareService.getMapValue("sub_category_page");
    this.getList(tabChangePage)
  },
  methods: {
    getlanguageList() {
      this.$api
        .getAPI({
          _action: "/language/all",
        })
        .then((res) => {
          this.languageList = res;
          for (var i = 0; i < this.languageList.length; i++) {
            this.languageList[i].value = "";
          }
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.$shareService.setMapValue("sub_category_item_limit", this.itemsPerPage);
      this.getList(this.currentPage);
    },
    getList(page, orderBy, sortBy) {

      let itemLimit = this.$shareService.getMapValue("category_item_limit");
     if(itemLimit != null){
      this.itemsPerPage = itemLimit;
     }
      if (page == null) {
        page = this.currentPage;
      }
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      this.searchObj.limit = this.itemsPerPage;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      if (this.id) {
        this.searchObj.parent_id = this.id;
      }
      this.$api
        .getAPI({
          _action: "/sub-category/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count,this.itemsPerPage);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }

          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No category available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(this.currentPage, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(this.currentPage)
    },
    showAddSubCategoryPopup() {
      this.$shareService.setMapValue("sub_category_page",this.currentPage);
      this.$router.push("/add-category/"+ this.id);
      // this.details = {};
      // this.$refs.textModal.showModal();
      // this.$refs.subCategoryForm.resetForm();
    },
    showUpdateSubCategoryPopup(data) {
      this.$shareService.setMapValue("sub_category_page",this.currentPage);
      this.getlanguageList();
      this.edituuid = data.uuid;
      this.editParentid = data.parent_id;
      this.$refs.editModal.showModal();
      // this.details = {};
      // this.details.id = this.list[index].id;
      // this.details.name = this.list[index].name;
      // this.details.image = this.list[index].image;

      // if (this.list[index].list.length > 0) {
      //   for (var i = 0; i < this.languageList.length; i++) {
      //     for (var j = 0; j < this.list[index].list.length; j++) {
      //       if (this.languageList[i].code == this.list[index].list[j].lang_code) {
      //         this.languageList[i].value = this.list[index].list[j].value;
      //       }
      //     }
      //   }
      // } else {
      //   for (var k = 0; k < this.languageList.length; k++) {
      //     this.languageList[k].value = "";
      //   }
      // }

      // this.$refs.textModal.showModal();
    },
    editProduct() {
      this.$shareService.setMapValue("sub_category_page",this.currentPage);
      this.$refs.editModal.closeModal();
      this.$router.push(
        "/edit-category/" +
          this.edituuid +
          "/" +
          this.editLangCode +
          "/" +
          this.editParentid
      );
    },
    save() {
      var obj = {
        name: this.details.name,
        parent_id: this.id,
        list: this.languageList,
      };
      if (this.details.id) {
        obj.id = this.details.id;
      }
      this.$api
        .postAPI({
          _action: "/category",
          _body: obj,
          _buttonId: "save-button",
        })
        .then(() => {
          this.uploadImage(this.details.id);
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    showDeletePopup(data) {
      this.$shareService.setMapValue("sub_category_page",this.currentPage);
      this.$refs.deleteModal.showModal(
        "Delete Category",
        "Are you sure you want to delete " + data.name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "/category/" + detail.id + "/sub-category",
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    onChange(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    uploadImage(id) {
      if (!this.file) {
        this.$refs.textModal.closeModal();
        this.getList(this.currentPage)
        return;
      }
      this.$api
        .uploadImageAPI({
          _action: "/category/photo",
          _key: "image",
          _file: this.file,
          _body: {
            id: id,
          },
          _buttonId: "save-button",
        })
        .then(() => {
          this.$refs.textModal.closeModal();
          this.getList(this.currentPage)
          this.$notify({
            type: "success",
            text: "Sub category photo uploaded successfullly",
          });
        })
        .catch(() => {});
    },
  },
};
</script>

