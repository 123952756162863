<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Newsletter</h4>
          </div>
        </div>
        <div
          class="page-content"
          v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage"
        >
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 10px"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                  />
                </div>
                <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getList(0)"
                  >Search</a
                >
                <a
                  class="fill-btn btn-style cursor-pointer"
                  v-on:click="resetFilter"
                  v-show="searchObj.keyword"
                  >Reset</a
                >
              </div>
              <div class="col-md-6 col-sm-6 form-group text-right">
                <a
                  v-on:click="exportCustomer()"
                  class="fill-btn btn-style cursor-pointer ml-3"
                  >Export</a
                >
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th id="id-title">Email</th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td style="text-align: left">
                            {{ ($refs.listPagination.currentPage - 1) * 10 + index + 1 }}
                          </td>
                          <td style="text-align: left">
                            {{ data.email }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList()" ref="errorComponent" />
                <TextModal ref="textModal">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      {{ details.id ? "Edit" : "Add" }} Language
                    </h5>
                    <button
                      type="button"
                      class="close"
                      v-on:click="$refs.textModal.closeModal"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <Form ref="brandForm" @submit="save">
                    <div class="modal-body">
                      <div class="col-lg-12 col-sm-12 mb-3">
                        <div class="text-center mb-4">
                          <img
                            :src="
                              details.flag && details.flag != null
                                ? details.flag
                                : '/images/placeholder.gif'
                            "
                            id="preview"
                            class="img-thumbnail"
                            style="border-radius: 100px; width: 120px; height: 120px"
                          /><br />
                          <div id="msg"></div>
                          <div id="image-form" style="margin: 0 auto; width: 90px">
                            <input
                              type="file"
                              name="img[]"
                              class="file"
                              id="pickFile"
                              v-on:change="onChange($event)"
                              accept="image/*"
                              style="display: none"
                            />
                            <div class="input-group my-1">
                              <input
                                type="text"
                                class="form-control"
                                disabled
                                placeholder="Upload File"
                                id="file"
                                style="display: none"
                              />
                              <div class="changePic" style="bottom: -25px">
                                <label for="pickFile"> Change</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12 col-sm-12">
                        <div class="form-group">
                          <label>Title</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            class="form-control"
                            name="title"
                            v-model="details.title"
                            placeholder="Title"
                            rules="required:title"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="title" class="text-danger" />
                        </div>
                      </div>

                      <div class="col-lg-12 col-sm-12">
                        <div class="form-group">
                          <label>Code</label>
                          <span class="text-danger">*</span>
                          <Field
                            type="text"
                            class="form-control"
                            name="code"
                            v-model="details.code"
                            placeholder="Code"
                            rules="required:code"
                            :validateOnInput="true"
                          />
                          <ErrorMessage name="code" class="text-danger" />
                        </div>
                      </div>

                      <div class="col-lg-12 col-sm-12">
                        <div class="form-group">
                          <label>Status</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="select"
                            class="form-control"
                            name="status"
                            v-model="details.status"
                            placeholder="Select Status"
                            rules="required:status"
                            :validateOnInput="true"
                          >
                            <option value="" selected>Select Status</option>
                            <option value="PENDING">Draft</option>
                            <option value="ACTIVE">Publish</option>
                            <option value="INACTIVE">UnPublish</option>
                          </Field>
                          <ErrorMessage name="status" class="text-danger" />
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button class="fill-btn" id="save-button">Save</button>
                      <button
                        type="button"
                        class="fil-btn"
                        id="cancel-button"
                        v-on:click="$refs.textModal.closeModal"
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                </TextModal>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-center"
          style="margin-top: 20%"
          v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage"
        >
          <h3>You do not have access to view language</h3>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteAllRecord" ref="deleteModal" />
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
import TextModal from "@/components/TextModal";
import Report from "../../Report";
export default {
  name: "LanguagePage",
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
    DeleteModal,
    Pagination,
    TextModal,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage Newsletter"),
      loader: true,
      isShowAccessDeniedMessage: false,
      offset: -1,
      itemsPerPage: 10,
      currentPage: 1,
      searchObj: {
        keyword: "",
      },
      details: {},
      list: [],
      deleteList: [],
      is_select: "0",
    };
  },
  mounted() {
    if (!this.accessObject) {
      this.isShowAccessDeniedMessage = true;
      this.$router.push("/access-denied");
      return;
    }
    var tabChangePage = this.$shareService.getMapValue("news_page");
    this.getList(tabChangePage);
  },
  methods: {
    exportCustomer() {
      var url = "/newsletter/export";
      url += "?keyword=" + this.searchObj.keyword;
      window.open(Report.REPORT_URL + "/api" + url);
    },
    pageChange(offset) {
      this.getList(offset);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.$shareService.setMapValue("news_item_limit", this.itemsPerPage);
      this.getList(this.currentPage);
    },
    getList(page, orderBy, sortBy) {
      let itemLimit = this.$shareService.getMapValue("news_item_limit");
      if (itemLimit != null) {
        this.itemsPerPage = itemLimit;
      }
      if (page == null) {
        page = this.currentPage;
      }
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      this.searchObj.limit = this.itemsPerPage;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/get-news-letter",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (this.$refs.listPagination) {
            if (res.count != null && res.count > 0) {
              this.$refs.listPagination.setTotalCount(res.count, this.itemsPerPage);
            } else {
              this.$refs.listPagination.setTotalCount(0);
            }
          }
          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Newsletter available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.updateLoader(false);
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
          }
        });
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(this.tabChangePage);
    },
  },
};
</script>

