<template>
  <input
    type="range"
    v-on:change="onChange"
    min="0"
    max="100"
    :value="val"
    class="slider"
    id="color_slider"
  />
</template>

 <style>
:root {
  --red-color: 232;
  --blue-color: 190;
  --green-color: 172;
}

.slider {
  display: inline;
  -webkit-appearance: none;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  width: 100%;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #ffcc99;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #ffcc99;
  cursor: pointer;
}

#color_slider {
  background: linear-gradient(to right, rgb(232, 200, 190), rgb(255, 150, 90));
}
</style>

<script>
export default {
  name: "ColorSlider",
  computed: {
    val() {
      return this.hexToValue(this.modelValue ? this.modelValue : "#ffc8be");
    },
  },
  props: {
    modelValue: {
      type: String
    },
  },
  methods: {
    onChange(event) {
      this.$emit(
        "update:modelValue",
        this.valueToHex(
          255,
          200 - event.target.value / 2,
          190 - event.target.value
        )
      );
    },
    valueToHex(r, g, b) {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    },

    hexToValue(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (result) {
        var b = parseInt(result[3], 16);
        return 190 - b;
      }
      return null;
    },
  },
};
</script>