<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Requested Products</h4>
          </div>
        </div>
        <div class="page-content" v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage">
          <div class="card-body py-0 px-0">
            <ul class="nav nav-pills m-3">
              <li class="nav-item cursor-pointer">
                <a class="nav-link" data-toggle="tab" v-on:click="setStatus('REQUESTED_PRODUCT',null)"
                  :class="{ active: currentTab == 'REQUESTED_PRODUCT' }">
                  <span>Requested Products</span></a>
              </li>
              <li class="nav-item cursor-pointer">
                <a class="nav-link" data-toggle="tab" v-on:click="setStatus('REJECTED_PRODUCT',null)"
                  :class="{ active: currentTab == 'REJECTED_PRODUCT' }">
                  <span>Rejected Products</span></a>
              </li>
            </ul>

            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i class="fa fa-search" style="position: absolute; margin: 10px" aria-hidden="true"></i>
                  <input type="text" class="form-control" v-on:keyup.enter="this.getList(this.currentPage);"
                    placeholder="Search" v-model="searchObj.keyword" />
                </div>
                <a class="fill-btn btn-style cursor-pointer ml-3"
                  v-on:click="this.getList(this.currentPage);">Search</a>
                <a class="fill-btn btn-style cursor-pointer" v-on:click="resetFilter"
                  v-show="searchObj.keyword">Reset</a>
              </div>
              <div class="col-md-6 text-right" v-if="currentTab == 'REQUESTED_PRODUCT'">
                <!-- <a
                  v-on:click="exportCustomer()"
                  class="fill-btn btn-style cursor-pointer ml-3"
                  data-toggle="modal"
                  >Export</a
                >

                <a
                  v-show="accessObject?.is_add == 1"
                  class="fill-btn cursor-pointer btn-style"
                  v-on:click="$router.push('/add-customer')"
                  >+ Add</a
                > -->

                
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table class="table table-striped table-bordered dataTable" style="width: 100%" role="grid"
                      aria-describedby="example_info">
                      <thead>
                        <tr role="row">
                            <th>Sr. No.</th>
                            <th class="sorting" id="id-name" v-on:click="
                              sorting('name', 'id-name')
                              ">
                              User Name
                            </th>
                            <th>Product Front Image</th>
                            <th>Product EAN Image</th>
                            <th>Product Ingredient Image</th>
                            <th class="sorting" id="id-code" v-on:click="
                              sorting('code', 'id-code')
                              ">
                              EAN Code
                            </th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                            <td style="text-align: left">
                              {{
                                 (currentPage - 1) * itemsPerPage + index + 1
                              }}
                            </td>
                            <td style="text-align: left">
                              {{ data.user ? data.user.name : "" }}
                            </td>
                            <td>
                              <img :src="data.image
                                ? data.image
                                : '/images/placeholder.gif'
                                " width="50" height="50" style="border-radius: 100px" />
                            </td>
                            <td>
                              <img :src="data.barcode_img
                                ? data.barcode_img
                                : '/img/placeholder.gif'
                                " width="50" height="50" style="border-radius: 100px" />
                            </td>
                            <td>
                              <img :src="data.ingredient_img
                                ? data.ingredient_img
                                : '/img/placeholder.gif'
                                " width="50" height="50" style="border-radius: 100px" />
                            </td>
                            <td style="text-align: left">
                              {{ data.barcode_number }}
                            </td>
                            <td style="text-align: left">{{ data.status }}</td>
                            <td v-show="accessObject?.is_view == 1">
                              <router-link :to="'/view-requested/' + data.id">
                                <a class="fill-btn btn-style cursor-pointer">View</a>&nbsp;
                              </router-link>
                              <a class="fill-btn btn-style cursor-pointer"
                                v-if="currentTab == 'REQUESTED_PRODUCT'"
                                v-on:click="$router.push('/accept/' + data.id)"
                                v-show="accessObject?.is_edit == 1">Accept</a>&nbsp;
                              <a class="fill-btn btn-style cursor-pointer "   v-if="currentTab == 'REQUESTED_PRODUCT'" v-on:click="showRejectPopup(data)"
                                v-show="accessObject?.is_delete == 1">Reject</a>&nbsp;
                            </td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                        ref="listPagination" />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="this.getList(this.currentPage);" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
        <div class="text-center" style="margin-top: 20%"
          v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage">
          <h3>You do not have access to view requested products</h3>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
import TextModal from "@/components/TextModal";
import Report from "../../Report";
export default {
  name: "RequestedProduct",
  components: {
    ErrorComponent,
    DeleteModal,
    Pagination,
    TextModal,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Requested Products"),
      loader: true,
      isShowAccessDeniedMessage: false,
      docFile: undefined,
      offset: -1,
      list: [],
      itemsPerPage: 10,
      searchObj: {
        keyword: "",
        status: "ACTIVE",
        category_id: ""
      },
      catgeories: [],
      deleteList: [],
      is_select: "0",
      currentTab: "REQUESTED_PRODUCT",
      delete_id: null,
      action_status: null,
      confirmation_id: null,
      currentPage: 1
    };
  },
  mounted() {
    if (!this.accessObject) {
      this.isShowAccessDeniedMessage = true;
      this.$router.push("/access-denied");
      return;
    }
    var tabChangePage = this.$shareService.getMapValue("requested_attribute");
    let tabValue = this.$shareService.getMapValue("requested_tab");
    if(tabValue != null){
      this.setStatus(tabValue,tabChangePage);
    }else{
      this.getList(tabChangePage);
    }
  },
  methods: {
    pageChange(offset) {
      this.getList(offset);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.$shareService.setMapValue("requested_item_limit", this.itemsPerPage);
      this.getList(this.currentPage);
    },
    setStatus(status,value) {
      this.searchObj.status = status;
      this.$shareService.setMapValue("requested_tab",status);
      this.currentTab = status;
      if(value != null){
        this.getList(value);
      }else{
        this.getList(1);
      }
    },
    getList(page, orderBy, sortBy) {

      let itemLimit = this.$shareService.getMapValue("requested_item_limit");
      if (itemLimit != null) {
        this.itemsPerPage = itemLimit;
      }
      if (page == null) {
        page = this.currentPage;
      }
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page - 1;
      if(this.currentTab === "REQUESTED_PRODUCT"){
        this.searchObj.type = "PENDING";
      }
      if(this.currentTab === "REJECTED_PRODUCT"){
        this.searchObj.type = "REJECTED";
      }
      this.currentPage = page;
      this.searchObj.limit = this.itemsPerPage;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/requested/product/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (this.$refs.listPagination) {
            if (res.count != null && res.count > 0) {
              this.$refs.listPagination.setTotalCount(res.count, this.itemsPerPage);
            } else {
              this.$refs.listPagination.setTotalCount(0);
            }
          }
          let title = "No Requested product available";
          if(this.currentTab == "REJECTED_PRODUCT"){
            title = "No Rejected product available"
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, title);
            this.$refs.errorComponent.updateLoader(false);
          }
          for (var i = 0; i < this.list.length; i++) {
            this.list[i].is_select = "0";
          }
          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
  

    
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(this.currentPage, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(this.currentPage);
    },
    
    showRejectPopup(data) {
      this.$shareService.setMapValue("requested_attribute", this.currentPage);
      this.$refs.deleteModal.showModal(
        "Reject Product",
        "Are you sure you want to reject " + data.barcode_number,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      var obj = {};
      if (detail.id) {
        obj.id = detail.id;
      }
      this.$api
        .postAPI({
          _action: "/product-status",
          _body: obj,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList(this.currentPage);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
  },
};
</script>
