<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/product')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ uuid ? "Edit" : "Add" }} Product</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <ul class="nav nav-pills">
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  v-on:click="getDetail('DETAIL')"
                  :class="{ active: currentTab == 'DETAIL' }"
                >
                  <span>Product Details</span></a
                >
              </li>
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  v-on:click="getCurrency('CURRENCY')"
                  :class="{ active: currentTab == 'CURRENCY' }"
                >
                  <span>Currencies</span></a
                >
              </li>
            </ul>
            <div v-show="currentTab == 'DETAIL'">
              <div class="row">
                <div class="col-lg-10 col-sm-10 float-none">
                  <div class="card border mb-0 radius-15">
                    <Form @submit="save">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-lg-12 col-sm-12 mb-3">
                            <div class="text-center mb-4">
                              <img
                                :src="
                                  details.image && details.image != null
                                    ? details.image
                                    : '/images/placeholder.gif'
                                "
                                id="preview"
                                class="img-thumbnail"
                                style="border-radius: 100px; width: 120px; height: 120px"
                              /><br />
                              <div id="msg"></div>
                              <div id="image-form" style="margin: 0 auto; width: 90px">
                                <Field
                                  type="file"
                                  name="img[]"
                                  class="file"
                                  id="pickFile"
                                  @change="selectedFile($event)"
                                  accept="image/*"
                                  style="display: none"
                                  :disabled="lang_code != 'EN'"
                                />
                                <div class="input-group my-1">
                                  <Field
                                    type="text"
                                    class="form-control"
                                    name="img-text"
                                    disabled
                                    placeholder="Upload File"
                                    id="file"
                                    style="display: none"
                                  />
                                  <div
                                    class="changePic"
                                    style="bottom: -25px"
                                    v-if="lang_code == 'EN'"
                                  >
                                    <label for="pickFile"> Change</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label> Name</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="name"
                                class="form-control"
                                placeholder="Name"
                                v-model="details.name"
                                rules="required:name"
                                :validateOnInput="true"
                              />
                              <ErrorMessage name="name" class="text-danger" />
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Price ($)</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="price"
                                class="form-control"
                                placeholder="Price"
                                v-model="details.price"
                                rules="required:price"
                                :validateOnInput="true"
                                @keypress="$helperService.allowOnlyNumericValue($event)"
                                :disabled="lang_code != 'EN' || details.id"
                              />
                              <ErrorMessage name="price" class="text-danger" />
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Barcode</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="barcode"
                                class="form-control"
                                placeholder="Barcode"
                                v-model="details.barcode_number"
                                rules="required:barcode"
                                :validateOnInput="true"
                                :disabled="lang_code != 'EN'"
                              />
                              <ErrorMessage name="barcode" class="text-danger" />
                            </div>
                          </div>

                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Meta Title</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="meta_title"
                                class="form-control"
                                placeholder="Meta Title"
                                v-model="details.meta_title"
                                rules="required:meta title"
                                :validateOnInput="true"
                                :disabled="lang_code != 'EN'"
                              />
                              <ErrorMessage name="meta_title" class="text-danger" />
                            </div>
                          </div>

                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Meta Description</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="meta_description"
                                class="form-control"
                                placeholder="Meta Description"
                                v-model="details.meta_description"
                                rules="required:meta description"
                                :validateOnInput="true"
                                :disabled="lang_code != 'EN'"
                              />
                              <ErrorMessage name="meta_description" class="text-danger" />
                            </div>
                          </div>

                          <div class="form-group col-md-6">
                            <label for="inputRole">Brand</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="form-control"
                              id="inputRole"
                              name="name_brand"
                              v-model="details.brand_id"
                              rules="required:brand"
                              :disabled="lang_code != 'EN'"
                            >
                              <option value="">Select Brand</option>
                              <option
                                v-for="(data, index) in brandList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.title }}
                              </option>
                            </Field>
                            <ErrorMessage name="name_brand" class="text-danger" />
                          </div>
                          <div class="form-group col-md-6">
                            <label for="inputRole">Ingredient</label>
                            <span class="text-danger">*</span>
                            <Multiselect
                              mode="tags"
                              trackBy="name"
                              label="name"
                              valueProp="id"
                              :searchable="true"
                              v-model="selectedIngredient"
                              :options="ingredientList"
                              rules="required:ingredient"
                              name="name_ingredient"
                              :disabled="lang_code != 'EN'"
                            />
                            <ErrorMessage name="name_ingredient" class="text-danger" />
                          </div>
                          <div class="form-group col-md-6">
                            <label for="inputRole">Main Category</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="form-control"
                              id="inputRole"
                              name="name_category"
                              v-model="details.categorie_id"
                              v-on:change="getSubCategoryList()"
                              rules="required:main category"
                              :disabled="lang_code != 'EN'"
                            >
                              <option value="">Select Main Category</option>
                              <option
                                v-for="(data, index) in categoryList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.name }}
                              </option>
                            </Field>
                            <ErrorMessage name="name_category" class="text-danger" />
                          </div>
                          <div class="form-group col-md-6">
                            <label for="inputRole">Category</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="form-control"
                              id="inputRole"
                              name="name_sub_category"
                              v-model="details.sub_categorie_id"
                              v-on:change="getSubSubCategoryList()"
                              rules="required:catergory"
                              :disabled="lang_code != 'EN'"
                            >
                              <option value="">Select Category</option>
                              <option
                                v-for="(data, index) in subCategoryList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.name }}
                              </option>
                            </Field>
                            <ErrorMessage name="name_sub_category" class="text-danger" />
                          </div>
                          <div class="form-group col-md-6">
                            <label for="inputRole">Sub Category</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="form-control"
                              id="inputRole"
                              name="name_sub_sub_category"
                              v-model="details.sub_sub_categorie_id"
                              rules="required:sub-catergory"
                              :disabled="lang_code != 'EN'"
                            >
                              <option value="">Select Sub-Category</option>
                              <option
                                v-for="(data, index) in subsubCategoryList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.name }}
                              </option>
                            </Field>
                            <ErrorMessage
                              name="name_sub_sub_category"
                              class="text-danger"
                            />
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Source URL</label>
                              <Field
                                type="text"
                                name="source_url"
                                class="form-control"
                                placeholder="Source URL"
                                v-model="details.source_url"
                                :disabled="lang_code != 'EN'"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Title Line</label>
                              <Field
                                type="text"
                                name="title_line"
                                class="form-control"
                                placeholder="Title Line"
                                v-model="details.title_line"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Volume</label>
                              <Field
                                type="text"
                                name="volume"
                                class="form-control"
                                placeholder="Volume"
                                v-model="details.volume"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Shade</label>
                              <Field
                                type="text"
                                name="shade"
                                class="form-control"
                                placeholder="Shade"
                                v-model="details.shade"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Shade Description</label>
                              <Field
                                type="text"
                                name="shade_description"
                                class="form-control"
                                placeholder="Shade Description"
                                v-model="details.shade_description"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Discounted From Price</label>
                              <Field
                                type="text"
                                name="discounted_from_price"
                                class="form-control"
                                placeholder="Discounted From Price"
                                v-model="details.discounted_from_price"
                                :disabled="lang_code != 'EN' || details.id"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Product ID</label>
                              <Field
                                type="text"
                                name="product_id"
                                class="form-control"
                                placeholder="Product ID"
                                v-model="details.product_id"
                                :disabled="lang_code != 'EN'"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Benefits</label>
                              <Field
                                type="text"
                                name="benefits"
                                class="form-control"
                                placeholder="Benefits"
                                v-model="details.benefits"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>How to apply</label>
                              <Field
                                type="text"
                                name="how_to_apply"
                                class="form-control"
                                placeholder="How to apply"
                                v-model="details.how_to_apply"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Fragrance Note</label>
                              <Field
                                type="text"
                                name="fragrance_note"
                                class="form-control"
                                placeholder="Fragrance Note"
                                v-model="details.fragrance_note"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Gender</label>
                              <Field
                                type="text"
                                name="gender"
                                class="form-control"
                                placeholder="Gender"
                                v-model="details.gender"
                              />
                            </div>
                          </div>
                          <div class="col-lg-12 col-sm-6">
                            <div class="form-group">
                              <label>Description</label>
                              <span class="text-danger">*</span>
                              <Field
                                as="textarea"
                                type="text"
                                name="description"
                                class="form-control"
                                placeholder="Descripiton"
                                v-model="details.description"
                                rules="required:description"
                                :validateOnInput="true"
                              />
                              <ErrorMessage name="description" class="text-danger" />
                            </div>
                          </div>
                        </div>
                        <div class="card-body" v-if="lang_code == 'EN'">
                          <div class="row">
                            <div class="col-lg-6 col-sm-6 mb-3">
                              <h3>Attributes</h3>
                            </div>
                            <div class="col-lg-6 col-sm-6 mb-3 text-right">
                              <a
                                class="fill-btn cursor-pointer btn-style"
                                v-on:click="add()"
                                >+ Add</a
                              >
                            </div>

                            <div class="col-lg-12">
                              <div class="table-responsive">
                                <div class="dataTables_wrapper dt-bootstrap4">
                                  <table
                                    class="table table-striped table-bordered dataTable"
                                    style="width: 100%"
                                    role="grid"
                                    aria-describedby="example_info"
                                  >
                                    <thead>
                                      <tr role="row">
                                        <th>Sr. No.</th>
                                        <th id="id-name">Attribute</th>
                                        <th id="id-bname">Option</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody v-if="list.length">
                                      <tr v-for="(data, index) in list" :key="index">
                                        <td style="text-align: left">
                                          {{ index + 1 }}
                                        </td>
                                        <td style="text-align: left">
                                          {{ data.attribute_master.title }}
                                        </td>
                                        <td style="text-align: left">
                                          {{ data.attribute_option.title }}
                                        </td>
                                        <td>
                                          <a
                                            class="fill-btn btn-style cursor-pointer"
                                            v-on:click="edit(data, index)"
                                            >Edit</a
                                          >
                                          <a
                                            class="fill-btn btn-style cursor-pointer"
                                            v-on:click="showDeletePopup(data, index)"
                                            >Delete</a
                                          >
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div
                                    class="text-center"
                                    style="margin-top: 30px"
                                    v-show="list.length == 0"
                                  >
                                    <h3>No Record Found</h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mt-3 mb-3">
                          <div class="col-lg-12 col-sm-12 text-center mt-2">
                            <button id="save-button" class="fill-btn px-4 ml-3">
                              Save
                            </button>
                            <button
                              type="button"
                              class="fil-btn px-4 ml-3"
                              v-on:click="
                                localStorage.setItem('lang', 'EN');
                                $router.go(-1);
                              "
                              id="cancel-button"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="currentTab == 'CURRENCY'">
              <div class="row">
                <div class="col-lg-10 col-sm-10 float-none">
                  <div class="card border mb-0 radius-15">
                    <div class="table-responsive">
                      <div class="dataTables_wrapper dt-bootstrap4">
                        <table
                          class="table table-striped table-bordered dataTable"
                          style="width: 100%"
                          role="grid"
                          aria-describedby="example_info"
                        >
                          <thead>
                            <tr role="row">
                              <th>Sr. No.</th>
                              <th>Code</th>
                              <th style="text-align: center">Symbol</th>
                              <th>Price</th>
                              <th>Discounted From Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(data, index) in currencyList" :key="index">
                              <td style="text-align: left">
                                {{ index + 1 }}
                              </td>
                              <td style="text-align: left">
                                {{ data.title }}
                              </td>
                              <td style="text-align: left">
                                {{ data.symbol }}
                              </td>

                              <td style="text-align: left">
                                <Field
                                  type="text"
                                  name="price"
                                  class="form-control"
                                  placeholder="Price"
                                  v-model="data.price"
                                  rules="required:price"
                                  :validateOnInput="true"
                                  @keypress="$helperService.allowOnlyNumericValue($event)"
                                />
                              </td>

                              <td style="text-align: left">
                                <Field
                                  type="text"
                                  name="price"
                                  class="form-control"
                                  placeholder="Discounted From Price"
                                  v-model="data.discounted_from_price"
                                  rules="required:discounted from price"
                                  :validateOnInput="true"
                                  @keypress="$helperService.allowOnlyNumericValue($event)"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="row mt-3 mb-3" v-if="details.id">
                      <div class="col-lg-12 col-sm-12 text-center mt-2">
                        <button
                          id="save1-button"
                          class="fill-btn px-4 ml-3"
                          @click="saveCurrency()"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          class="fil-btn px-4 ml-3"
                          v-on:click="
                            localStorage.setItem('lang', 'EN');
                            $router.go(-1);
                          "
                          id="cancel-button"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <TextModal ref="textModal">
      <div class="modal-header">
        <h5 class="modal-title">Save Attribute</h5>
        <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-lg-12 col-sm-12 mb-3">
          <div class="form-group col-md-12">
            <label for="inputRole">Attribute</label>
            <span class="text-danger">*</span>
            <Field
              as="select"
              class="form-control"
              id="inputRole3"
              v-model="attribute.attribute_master_id"
              rules="required:attribute"
              v-on:change="getAttributeOptionList()"
            >
              <option value="">Select Attribute</option>
              <option
                v-for="(data, index) in attributeList"
                :key="index"
                :value="data.id"
              >
                {{ data.title }}
              </option>
            </Field>
            <ErrorMessage name="name_attribute" class="text-danger" />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12 mb-3">
          <div class="form-group col-md-12">
            <label for="inputRole">Option</label>
            <span class="text-danger">*</span>
            <Field
              as="select"
              class="form-control"
              id="inputRole1"
              v-model="attribute.attribute_option_id"
              rules="required:attribute option"
            >
              <option value="">Select Attribute Options</option>
              <option
                v-for="(data, index) in attributeOptionList"
                :key="index"
                :value="data.id"
              >
                {{ data.title }}
              </option>
            </Field>
            <ErrorMessage name="name_attribute_option" class="text-danger" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          id="cancle-button"
          class="btn btn-secondary position-relative w-auto px-4"
          data-dismiss="modal"
          v-on:click="$refs.textModal.closeModal"
        >
          Close
        </button>
        <button
          id="document-button"
          type="button"
          class="btn btn-primary position-relative w-auto mt-0 mb-0 px-4"
          v-on:click="saveAttribute"
        >
          Save
        </button>
      </div>
    </TextModal>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal";
import Multiselect from "@vueform/multiselect";
export default {
  name: "AddProduct",
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
    Multiselect,
  },
  data() {
    return {
      uuid: this.$route.params.id,
      code: this.$route.params.code,
      brandList: [],
      categoryList: [],
      subCategoryList: [],
      subsubCategoryList: [],
      file: undefined,
      ingredientList: [],
      details: {
        brand_id: "",
        categorie_id: "",
        sub_categorie_id: "",
        sub_sub_categorie_id: "",
      },
      selectedIngredient: [],
      list: [],
      attributeList: [],
      attributeOptionList: [],
      attribute: {
        attribute_master: "",
        attribute_option: "",
        attribute_master_id: "",
        attribute_option_id: "",
      },
      attributes: [],
      lang_code: "",
      currentTab: "DETAIL",
      currencyList: [],
    };
  },
  mounted() {
    this.getAttributeList();
    if (this.uuid) {
      if (!this.code) {
        this.$router.go(-1);
      }
      this.lang_code = this.code;
      this.getDetail();
    } else {
      this.lang_code = "EN";
      this.getBrandList();
      this.getCategoryList();
      this.getIngredientList();
    }
    localStorage.setItem("lang", this.lang_code);
  },
  methods: {
    add() {
      this.attribute = {
        attribute_master: "",
        attribute_option: "",
        attribute_master_id: "",
        attribute_option_id: "",
        type: "ADD",
        index: 0,
      };
      this.attributeOptionList = [];
      this.$refs.textModal.showModal();
    },
    edit(data, index) {
      var temp = JSON.stringify(data);
      this.attribute = JSON.parse(temp);
      this.attribute = data;
      this.attribute.type = "EDIT";
      this.attribute.index = index;

      this.$refs.textModal.showModal();
    },
    showDeletePopup(data, index) {
      console.log(data);
      this.list.splice(index, 1);
    },
    saveAttribute() {
      if (!this.attribute.attribute_master_id) {
        this.$notify({
          type: "error",
          text: "Please select attribute",
        });
        return false;
      }
      if (!this.attribute.attribute_option_id) {
        this.$notify({
          type: "error",
          text: "Please select attribute option",
        });
        return false;
      }
      for (var i = 0; i < this.attributeList.length; i++) {
        if (this.attributeList[i].id == this.attribute.attribute_master_id) {
          this.attribute.attribute_master = this.attributeList[i];
        }
      }
      for (var j = 0; j < this.attributeOptionList.length; j++) {
        if (this.attributeOptionList[j].id == this.attribute.attribute_option_id) {
          this.attribute.attribute_option = this.attributeOptionList[j];
        }
      }
      console.log(this.attribute);

      if (this.attribute.type == "ADD") {
        this.list.push(this.attribute);
      } else {
        this.list[this.attribute.index] = this.attribute;
      }
      this.$refs.textModal.closeModal();
    },
    getAttributeList() {
      this.$api
        .getAPI({
          _action: "/all-attributes",
        })
        .then((res) => {
          this.attributeList = res.list;
        })
        .catch(() => {});
    },
    getAttributeOptionList() {
      setTimeout(() => {
        this.$api
          .getAPI({
            _action: "/attribute-options/" + this.attribute.attribute_master_id,
          })
          .then((res) => {
            this.attributeOptionList = res.list;
          })
          .catch(() => {});
      }, 1000);
    },
    getBrandList() {
      this.$api
        .getAPI({
          _action: "/brand/list",
        })
        .then((res) => {
          this.brandList = res.list;
          if (this.details.brand_id != null) {
            this.brand_id = this.details.brand_id;
          }
        })
        .catch(() => {});
    },
    getIngredientList() {
      this.$api
        .getAPI({
          _action: "/all/ingredients",
        })
        .then((res) => {
          this.ingredientList = res.list;

          this.selectedIngredient = [];
          if (this.details.ingredient) {
            for (var i = 0; i < this.details.ingredient.length; i++) {
              if (this.details.ingredient[i].id) {
                this.selectedIngredient.push(this.details.ingredient[i].id);
              }
            }
          }
        })
        .catch(() => {});
    },
    getCategoryList() {
      this.$api
        .getAPI({
          _action: "/category/list",
        })
        .then((res) => {
          this.categoryList = res.list;
          if (this.details.categorie_id != null) {
            this.categorie_id = this.details.categorie_id;
          }
        })
        .catch(() => {});
    },
    getSubCategoryList() {
      var obj = {
        category_id: this.details.categorie_id,
      };
      this.$api
        .getAPI({
          _action: "/sub-category/list",
          _body: obj,
        })
        .then((res) => {
          this.subCategoryList = res.list;
          if (this.details.sub_categorie_id != null) {
            this.sub_categorie_id = this.details.sub_categorie_id;
          }
        })
        .catch(() => {});
    },

    getSubSubCategoryList() {
      var obj = {
        category_id: this.details.sub_categorie_id,
      };
      this.$api
        .getAPI({
          _action: "/sub-category/list",
          _body: obj,
        })
        .then((res) => {
          this.subsubCategoryList = res.list;
          if (this.details.sub_sub_categorie_id != null) {
            this.sub_sub_categorie_id = this.details.sub_sub_categorie_id;
          }
        })
        .catch(() => {});
    },
    getCurrency(currentTab) {
      this.currentTab = currentTab;
      this.$api
        .getAPI({
          _action: "/currency/all",
        })
        .then((res) => {
          this.currencyList = res;
          if (this.details.id) {
            for (var i = 0; i < this.currencyList.length; i++) {
              for (var j = 0; j < this.details.currencies.length; j++) {
                if (this.currencyList[i].title == this.details.currencies[j].symbol) {
                  this.currencyList[i].price = this.details.currencies[j].price
                    ? this.details.currencies[j].price
                    : "";
                  this.currencyList[i].discounted_from_price = this.details.currencies[j]
                    .discounted_from_price
                    ? this.details.currencies[j].discounted_from_price
                    : "";
                }
              }
            }
          } else {
            for (var k = 0; k < this.currencyList.length; k++) {
              this.currencyList[k].price = "";
              this.currencyList[k].discounted_from_price = "";
            }
          }
        })
        .catch(() => {});
    },
    getDetail() {
      this.currentTab = "DETAIL";
      if (this.uuid) {
        this.$api
          .getAPI({
            _action: "/product/detail/admin/" + this.uuid,
            _body: { lang: this.lang_code },
          })
          .then((res) => {
            res.barcode_number = res.barcode;
            this.details = res;
            this.list = [];
            for (var i = 0; i < this.details.attributes.length; i++) {
              for (var j = 0; j < this.details.attributes[i].list.length; j++) {
                this.list.push(this.details.attributes[i].list[j]);
              }
            }

            this.getBrandList();
            this.getIngredientList();
            this.getCategoryList();
            this.getSubCategoryList();
            this.getSubSubCategoryList();
            this.getCurrency("DETAIL");
          })
          .catch(() => {});
      }
    },
    saveCurrency() {
      if (this.details.id) {
        this.$api
          .postAPI({
            _action: "/product-currency/" + this.details.id,
            _body: { list: this.currencyList },
            _buttonId: "save1-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {});
      }
    },
    save() {
      if (!this.list.length) {
        this.$notify({
          type: "error",
          text: "Please select attributes",
        });
        return false;
      }
      var list = [];
      for (var k = 0; k < this.list.length; k++) {
        var obj = {
          attribute_master_id: this.list[k].attribute_master_id,
          attribute_option_id: this.list[k].attribute_option_id,
        };
        list.push(obj);
      }
      this.details.attributes = list;
      this.details.selectedIngredient = this.selectedIngredient;
      this.details.lang_code = this.lang_code;
      if (this.uuid) {
        this.details.barcode = this.details.barcode_number;
        this.details.uuid = this.uuid;
        this.$api
          .putAPI({
            _action: "/product",
            _body: this.details,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (res) {
              if (this.file) {
                this.uploadImage(res.uuid);
                return;
              }

              localStorage.setItem("lang", "EN");
              this.$router.go(-1);
            }
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/product",
            _body: this.details,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (this.file) {
              this.uploadImage(res.uuid);
              return;
            }
            localStorage.setItem("lang", "EN");
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    uploadImage(id) {
      if (!this.file) {
        localStorage.setItem("lang", "EN");
        this.$router.go(-1);
        return;
      }
      this.$api
        .uploadImageAPI({
          _action: "/product/photo",
          _key: "image",
          _file: this.file,
          _body: {
            uuid: id,
          },
          _buttonId: "save-button",
        })
        .then(() => {
          localStorage.setItem("lang", "EN");
          this.$router.go(-1);
        });
    },
  },
};
</script>

