<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/requested')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">
                View
                {{ details.product_status == "PENDING" ? "Requested" : "Rejected" }}
                Product
              </h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form @submit="save">
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Product Front Image</label>
                            <span class="text-danger">*</span>
                            <div class="text-center">
                              <img
                                :src="
                                  details.image && details.image != null
                                    ? details.image
                                    : '/images/placeholder.gif'
                                "
                                id="preview"
                                class="img-thumbnail"
                                width="100"
                                style="border-radius: 100px; height: 100px"
                              /><br />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Product EAN Image</label>
                            <span class="text-danger">*</span>
                            <div class="text-center">
                              <img
                                :src="
                                  details.barcode_img && details.barcode_img != null
                                    ? details.barcode_img
                                    : '/images/placeholder.gif'
                                "
                                id="barcode"
                                class="img-thumbnail"
                                width="100"
                                style="border-radius: 100px; height: 100px;
}"
                              /><br />
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Product Ingredient Image</label>
                            <span class="text-danger">*</span>
                            <div class="text-center">
                              <img
                                :src="
                                  details.ingredient_img && details.ingredient_img != null
                                    ? details.ingredient_img
                                    : '/images/placeholder.gif'
                                "
                                id="barcode"
                                class="img-thumbnail"
                                width="100"
                                style="border-radius: 100px; height: 100px;
}"
                              /><br />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group" style="margin-top: 10px">
                            <label> User Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="user_name"
                              class="form-control"
                              placeholder="User Name"
                              v-model="details.user.name"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group" style="margin-top: 10px">
                            <label> EAN Code</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="code"
                              class="form-control"
                              placeholder="EAN Code"
                              v-model="details.barcode_number"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="col-lg-12 col-sm-12 text-center mt-2"
                          v-show="details.product_status == 'PENDING'"
                        >
                          <button
                            v-show="accessObject?.is_edit == 1"
                            type="button"
                            class="fill-btn px-4 ml-3"
                            v-on:click="$router.push('/accept/' + details.id)"
                          >
                            Accept
                          </button>
                          <button
                            v-show="accessObject?.is_edit == 1"
                            type="button"
                            class="fil-btn px-4 ml-3"
                            id="cancel-button"
                            v-on:click="showRejectPopup()"
                          >
                            Reject
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            id="cancel-button"
                            v-on:click="$router.go(-1)"
                          >
                            Back
                          </button>
                        </div>
                        <div
                          class="col-lg-12 col-sm-12 text-center mt-2"
                          v-show="details.product_status != 'PENDING'"
                        >
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            id="cancel-button"
                            v-on:click="$router.go(-1)"
                          >
                            Back
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import DeleteModal from "@/components/DeleteModal";
export default {
  name: "ViewRequestedProduct",
  components: {
    DeleteModal,
    Form,
    Field,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Requested Products"),
      isShowAccessDeniedMessage: false,
      id: this.$route.params.id,
      type: this.$route.query.type,
      frontImage: undefined,
      eanImage: undefined,
      details: {
        user: {
          name: "",
        },
      },
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "/requested/product/detail/" + this.id,
        })
        .then((res) => {
          this.details = res;
          if (!res.user) {
            res.user = {};
          }
        })
        .catch(() => {});
    },
    showRejectPopup() {
      this.$refs.deleteModal.showModal(
        "Reject Product",
        "Are you sure you want to reject " + this.details.user.name,
        this.details
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      var obj = {};
      if (detail.id) {
        obj.id = detail.id;
      }
      this.$api
        .postAPI({
          _action: "/product-status",
          _body: obj,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
  },
};
</script>

