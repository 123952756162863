<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="row col-lg-6 col-sm-6 my-auto">
              <h4 class="mb-0 lg-bld">My Profile</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form @submit="save" ref="myProfileForm">
                      <div class="row">
                        <div class="col-lg-12 col-sm-12 mb-3">
                          <div class="text-center mb-4">
                            <img :src="
                              details.image && details.image != null
                                ? details.image
                                : '/images/placeholder.gif'
                            " id="preview" class="img-thumbnail" style="
                                border-radius: 100px;
                                width: 120px;
                                height: 120px;
                              " /><br />
                            <div id="msg"></div>
                            <div id="image-form" style="margin: 0 auto; width: 90px">
                              <input type="file" name="img[]" class="file" id="pickFile" @change="selectedFile($event)"
                                accept="image/*" style="display: none" />
                              <div class="input-group my-1">
                                <input type="text" class="form-control" disabled placeholder="Upload File" id="file"
                                  style="display: none" />
                                <div class="changePic" style="bottom: -25px">
                                  <label for="pickFile"> Change</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Name</label>
                            <span class="text-danger">*</span>
                            <Field type="text" name="name" class="form-control" placeholder="Name"
                              v-model="details.name" rules="required:name" :validateOnInput="true" />
                            <ErrorMessage name="name" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Mobile number</label>
                            <span class="text-danger">*</span>
                            <Field type="text" name="phone" placeholder="Phone" v-slot="{ field }"
                              v-model="details.mobile_number" rules="required:mobile number|phone"
                              :validateOnInput="true">
                              <PhoneCode id="mobile" placeholder="Mobile Number" v-model:countryCode="
                                details.country_code
                              " v-model="details.mobile_number" v-model:dialCode="details.dial_code" v-bind="field" />
                              <ErrorMessage name="phone" class="text-danger" />
                            </Field>
                            <!-- <Field type="text" name="mobile" placeholder="Phone no." v-slot="{ field }" rules="required:phone|phone">
                              <PhoneCode id="mobile" placeholder="Mobile Number" v-model:countryCode="
                                details.country_code
                              " v-model="details.mobile_number" v-model:dialCode="details.dial_code" v-bind="field" />
                            </Field>
                            <ErrorMessage name="mobile" class="text-danger" /> -->
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Email</label>
                            <span class="text-danger">*</span>
                            <Field type="email" name="email" class="form-control" placeholder="Email"
                              v-model="details.email" rules="required:email|email" :validateOnInput="true" />
                            <ErrorMessage name="email" class="text-danger" />
                          </div>
                        </div>
                        <div class="
                            col-lg-12 col-sm-12
                            mt-2
                            d-flex
                            justify-content-end
                          ">
                          <button type="button" id="forgot-button" class="fill-btn px-4 ml-3"
                            v-on:click="showChangePasswordPopup()">
                            Change Password
                          </button>
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TextModal ref="textModal">
      <div class="modal-header pt-0">
        <h5 class="modal-title">Change Password</h5>
        <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form ref="changePasswordForm" @submit="changePassword">
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>Old Password</label>
            <span class="text-danger">*</span>
            <Field type="password" name="_password" class="form-control" placeholder="Old Password"
              v-model="old_password" rules="required:password" :validateOnInput="true" />
            <ErrorMessage name="_password" class="text-danger" />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>New Password</label>
            <span class="text-danger">*</span>
            <Field type="password" name="name_password" class="form-control" placeholder="New Password"
              v-model="new_password" rules="required:password|minLength:6" :validateOnInput="true" />
            <ErrorMessage name="name_password" class="text-danger" />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>Confirm Password</label>
            <span class="text-danger">*</span>
            <Field type="password" placeholder="Confirm Password" name="name_ccpassword" class="form-control"
              rules="required:confirm password|confirmed:@name_password" :validateOnInput="true" />
            <ErrorMessage name="name_ccpassword" class="text-danger" />
          </div>
        </div>
        <div class="modal-footer pb-0">
          <button class="fill-btn" id="change-password-button" style="width: 20%; border-radius: 5px">
            Save
          </button>
          <button type="button" class="fil-btn" id="cancel-button" v-on:click="$refs.textModal.closeModal"
            style="width: 20%; border-radius: 5px">
            Cancel
          </button>
        </div>
      </Form>
    </TextModal>
  </div>
</template>
<script>
import TextModal from "@/components/TextModal";
import PhoneCode from "../../components/PhoneCodePicker/PhoneCode.vue";
import {
  Form,
  Field,
  ErrorMessage,
} from "vee-validate";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
    PhoneCode
  },
  name: "MyProfile",
  data() {
    return {
      id: this.$route.params.id,
      file: undefined,
      details: {},
      password: "",
      confirm_password: "",
    };
  },
  mounted() {
    this.getDetail();
    // console.log('this.$parent',document.getElementById("loginuser-name"))
    console.log('$parent', this.$parent.$parent.callMethod())
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "/users/me",
        })
        .then((res) => {
          this.details = res;
        })
        .catch(() => { });
    },
    save() {
      this.$api
        .putAPI({
          _action: "/edit/admin",
          _body: this.details,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (this.file) {
            this.uploadImage(this.details.id);
            return;
          }
          if (document.getElementById("loginuser-name")) {
            document.getElementById("loginuser-name").innerHTML = this.details.name
          }
          this.$router.go(-1);
          this.$notify({
            type: "success",
            text: res.message,
          });
          // this.$parent.$parent.callMethod()
        })
        .catch(() => { });
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    uploadImage(id) {
      if (!this.file) {
        this.$router.go(-1);
        return;
      }
      this.$api
        .uploadImageAPI({
          _action: "/user/photo",
          _key: "image",
          _file: this.file,
          _body: {
            id: id,
          },
          _buttonId: "save-button",
        })
        .then(() => {
          if (document.getElementById("loginuser-image")) {
            var url = window.URL.createObjectURL(this.file);
            document.getElementById("loginuser-image").src = url
          }
          this.$router.go(-1);
        });
    },
    showChangePasswordPopup() {
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    changePassword() {
      var obj = {};
      (obj.old_password = this.old_password),
        (obj.new_password = this.new_password);
      this.$api
        .putAPI({
          _action: "/reset/password",
          _body: obj,
          _buttonId: "change-password-button"
        })
        .then(() => {
          this.$refs.textModal.closeModal()
          localStorage.clear()
          this.$router.push('/login')
        })
        .catch(() => { });
    },
  },
};
</script>