<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Brands</h4>
          </div>
        </div>
        <div class="page-content" v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i class="fa fa-search" style="position: absolute; margin: 10px" aria-hidden="true"></i>
                  <input type="text" class="form-control" v-on:keyup.enter="getList(currentPage)"placeholder="Search"
                    v-model="searchObj.keyword" />
                </div>
                <div class="ml-3">
                  <select class="form-control mr-3" @change="getList(currentPage)" v-model="searchObj.filter">
                    <option selected value="">Select to search</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="F">F</option>
                    <option value="G">G</option>
                    <option value="H">H</option>
                    <option value="I">I</option>
                    <option value="J">J</option>
                    <option value="K">K</option>
                    <option value="L">L</option>
                    <option value="M">M</option>
                    <option value="N">N</option>
                    <option value="O">O</option>
                    <option value="P">P</option>
                    <option value="Q">Q</option>
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="T">T</option>
                    <option value="U">U</option>
                    <option value="V">V</option>
                    <option value="W">W</option>
                    <option value="X">X</option>
                    <option value="Y">Y</option>
                    <option value="Z">Z</option>
                  </select>
                </div>
                <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getList(currentPage)">Search</a>
                <a class="fill-btn btn-style cursor-pointer" v-on:click="resetFilter" v-show="searchObj.keyword">Reset</a>
              </div>
              <div style="cursor: pointer" class="col-lg-6 col-sm-6 txt-right form-group">
                <a v-show="accessObject?.is_add == 1" class="fill-btn cursor-pointer"
                  style="padding: 11px; margin-right: 16px" v-on:click="showAddBrandPopup">+ Add
                </a>

                <a v-show="accessObject?.is_delete == 1" class="fill-btn cursor-pointer"
                  style="padding: 11px; margin-right: 16px" v-on:click="deleteAllPopup">Delete
                </a>
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table class="table table-striped table-bordered dataTable" style="width: 100%" role="grid"
                      aria-describedby="example_info">
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th>Image</th>
                          <th class="sorting" id="id-name" v-on:click="sorting('name', 'id-name')">
                            Name
                          </th>
                          <th class="sorting" id="id-is_featured" v-on:click="sorting('is_featured', 'id-is_featured')">
                            Featured</th>
                          <th v-show="accessObject?.is_delete == 1">
                            <input type="checkbox" id="deleteAll" v-show="accessObject?.is_delete == 1"
                              v-on:click="selectAllData($event)" true-value="1" false-value="0" class="mr-2"
                              v-model="is_select" :class="is_select == '0' ? 'a' : 'b'" /> Select
                          </th>
                          <th v-show="accessObject?.is_edit == 1">Action</th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td style="text-align:left;">
                            {{
                              (currentPage - 1) * itemsPerPage + index + 1
                            }}
                          </td>
                          <td>
                            <img :src="data.image
                                  ? data.image
                                  : '/images/placeholder.gif'
                                " width="40" height="40" style="border-radius: 100px" />
                          </td>
                          <td style="text-align:left;">
                            {{ data.name }}
                          </td>
                          <td>
                            <div class="switchToggleRead">
                              <input type="checkbox" true-value=1 false-value=0 v-model="data.is_featured"
                                @change="checkFeatured(data)" />
                            </div>
                          </td>
                          <td v-show="accessObject?.is_delete == 1">

                            <input type="checkbox" :id="'delete_' + data.id" v-show="accessObject?.is_delete == 1"
                              v-on:click="deleteData($event, data)" v-model="data.is_select" true-value="1"
                              false-value="0" class="mr-2" :class="data.is_select == '1' ? 'a' : 'b'" />
                          </td>
                          <td v-show="accessObject?.is_edit == 1">
                            <a class="fill-btn btn-style cursor-pointer" v-on:click="
                             goProductAttribute(data)
                              ">Product Attributes</a>
                            <a class="fill-btn btn-style cursor-pointer" v-on:click="showUpdateBrandPopup(data)"
                              v-show="accessObject?.is_edit == 1">Edit</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                        ref="listPagination" />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList()" ref="errorComponent" />
                <TextModal ref="textModal">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      {{ details.id ? "Edit" : "Add" }} Brand
                    </h5>
                    <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <Form ref="brandForm" @submit="save">
                    <div class="modal-body" style="overflow-y: scroll;height: 500px;">
                      <div class="row">
                        <div class="col-lg-12 col-sm-12 mb-3">
                          <div class="text-center mb-4">
                            <img :src="details.image && details.image != null
                              ? details.image
                              : '/images/placeholder.gif'
                              " id="preview" class="img-thumbnail" style="
                              border-radius: 100px;
                              width: 120px;
                              height: 120px;
                            " /><br />
                            <div id="msg"></div>
                            <div id="image-form" style="margin: 0 auto; width: 90px">
                              <input type="file" name="img[]" class="file" id="pickFile" v-on:change="onChange($event)"
                                accept="image/*" style="display: none" />
                              <div class="input-group my-1">
                                <input type="text" class="form-control" disabled placeholder="Upload File" id="file"
                                  style="display: none" />
                                <div class="changePic" style="bottom: -25px">
                                  <label for="pickFile"> Change</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Brand Name</label>
                            <span class="text-danger">*</span>
                            <Field type="text" class="form-control" name="name" v-model="details.name"
                              placeholder="Brand Name" rules="required:name" :validateOnInput="true" />
                            <ErrorMessage name="name" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6" v-for="(data, index) in  languageList " :key="index">
                          <div class="form-group">
                            <label>Brand Name ({{ data.code }}<img :src="data.flag ? data.flag : '/img/placeholder.png'"
                                style="width:20px; height:17px">)</label>
                            <Field type="text" class="form-control" :name="'name_' + data.code" v-model="data.value"
                              :placeholder="'Brand Name (' + data.code + ')'" :validateOnInput="true" />
                            <ErrorMessage :name="'name_' + data.code" class="text-danger" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button class="fill-btn" id="save-button">Save</button>
                      <button type="button" class="fil-btn" id="cancel-button" v-on:click="$refs.textModal.closeModal">
                        Cancel
                      </button>
                    </div>
                  </Form>
                </TextModal>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center" style="margin-top: 20%" v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage">
          <h3>You do not have access to view brand</h3>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteAllRecord" ref="deleteModal" />
    <TextModal ref="editModal">
    <div class="modal-header">
      <h5 class="modal-title">
        Select Language
      </h5>
      <button type="button" class="close" v-on:click="$refs.editModal.closeModal">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Form @submit="editProduct">
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-sm-12 mb-3">
            <div class="form-group col-md-6">
              <label for="inputRole">Language</label>
              <span class="text-danger">*</span>
              <Field as="select" class="form-control" id="inputRole" name="name_language" v-model="editLangCode"
                rules="required:language">
                <option value="">Select Language</option>
                <option v-for="(data, index) in languageList" :key="index" :value="data.code">
                  {{ data.title }} ({{ data.code }})
                </option>
              </Field>
              <ErrorMessage name="name_language" class="text-danger" />
            </div>

          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="fill-btn" id="save-button">Go</button>
        <button type="button" class="fil-btn" id="cancel-button" v-on:click="$refs.editModal.closeModal">
          Cancel
        </button>
      </div>
    </Form>
  </TextModal>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import TextModal from "@/components/TextModal";
import Pagination from "@/components/Pagination";
export default {
  name: "BrandPage",
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
    DeleteModal,
    TextModal,
    Pagination,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage Brands"),
      loader: true,
      isShowAccessDeniedMessage: false,
      offset: -1,
      file: undefined,
      itemsPerPage: 10, searchObj: {
        keyword: "",
        filter: ""
      },
      details: {
        is_featured: ""
      },
      list: [],
      deleteList: [],
      currentPage:1,
      is_select: '0',
      languageList: [],
    };
  },
  mounted() {
    if (!this.accessObject) {
      this.isShowAccessDeniedMessage = true;
      this.$router.push("/access-denied");
      return;
    }
    this.getlanguageList();
    var tabChangePage = this.$shareService.getMapValue("brand_page");
    this.getList(tabChangePage)
  },
  methods: {
    
    goProductAttribute(data){
      this.$router.push('/product_attribute/brand/' + data.id);
      this.$shareService.setMapValue("brand_page",this.currentPage);
    },
    getlanguageList() {
      this.$api
        .getAPI({
          _action: "/language/all",
        })
        .then((res) => {
          this.languageList = res;
          for (var i = 0; i < this.languageList.length; i++) {
            this.languageList[i].value = "";
          }
        })
        .catch(() => {
        });
    },
    editProduct() {
      this.$shareService.setMapValue("brand_page",this.currentPage);
      this.$refs.editModal.closeModal();
      this.$router.push('/edit-brand/' + this.edituuid + "/" + this.editLangCode);
    },
    pageChange(offset) {
      this.getList(offset );
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.$shareService.setMapValue("brand_item_limit", this.itemsPerPage);
      this.getList(this.currentPage);
    },
    getList(page, orderBy, sortBy) {
      let itemLimit = this.$shareService.getMapValue("brand_item_limit");
     if(itemLimit != null){
      this.itemsPerPage = itemLimit;
     }
      if (page == null) {
        page = this.currentPage;
      }
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      this.searchObj.limit = this.itemsPerPage;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/brand/pagination/list/admin",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count,this.itemsPerPage);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No brand available");
            this.$refs.errorComponent.updateLoader(false);
          }
          for (var i = 0; i < this.list.length; i++) {
            this.list[i].is_select = "0";
          }
          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.updateLoader(false);
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
          }
        });
    },
    showAddBrandPopup() {
      this.$router.push('/add-brand');
      // this.details = {};
      // this.$refs.textModal.showModal();
      // this.$refs.brandForm.resetForm();
    },
    deleteAllPopup() {
      this.$shareService.setMapValue("brand_page",this.currentPage);
      this.$refs.deleteModal.showModal(
        "Delete Brand",
        "Are you sure you want to delete " + this.deleteList.length,
        + " brands"
      );
    },
    deleteAllRecord() {
      this.$api
        .postAPI({
          _action: "/delete/brand-all",
          _body: { "list": this.deleteList }
        })
        .then((res) => {
          if (res) {
            this.deleteList = [];
            this.is_select = '0';

            this.$refs.deleteModal.closeModal();
            this.getList(this.currentPage);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },

    deleteData(event, data) {
      if (event.target.checked) {
        this.deleteList.push(data.id);
      } else {
        for (var i = 0; i < this.deleteList.length; i++) {
          if (this.deleteList[i] == data.id) {
            this.deleteList.splice(i, 1);
          }
        }
      }

      setTimeout(() => {
        this.is_select = "1";
        for (var j = 0; j < this.list.length; j++) {
          if (this.list[j].is_select == "0") {
            this.is_select = "0";
            break;
          }
        }
      }, 1000);


    },
    selectAllData(event) {
      if (event.target.checked) {
        for (var i = 0; i < this.list.length; i++) {
          this.deleteList.push(this.list[i].id);
          this.list[i].is_select = "1";
        }
      } else {
        this.deleteList = [];
        for (var j = 0; j < this.list.length; j++) {
          this.list[j].is_select = "0";
        }
      }
    },
    showUpdateBrandPopup(data) {
      this.$shareService.setMapValue("brand_page",this.currentPage);
      this.getlanguageList();
      this.edituuid = data.uuid;
      this.$refs.editModal.showModal();
    },
    save() {
      var obj = {
        name: this.details.name,
        list: this.languageList
      };
      if (this.details.id) {
        obj.id = this.details.id;
        this.$api
          .putAPI({
            _action: "/brand",
            _body: obj,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (this.details.id) {
              this.uploadImage(this.details.id);
              this.$refs.textModal.closeModal();
              this.getList(this.currentPage);
            } else {
              this.uploadImage(res.brand_id);
              this.$refs.textModal.closeModal();
              this.getList(this.currentPage);
            }
          })
          .catch(() => {
            this.$refs.deleteModal.removeLoader();
          });
      } else {
        this.$api
          .postAPI({
            _action: "/brand",
            _body: obj,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (this.details.id) {
              this.uploadImage(this.details.id);
              this.$refs.textModal.closeModal();
              this.getList(this.currentPage);
            } else {
              this.uploadImage(res.brand_id);
              this.$refs.textModal.closeModal();
              this.getList(this.currentPage);
            }
          })
          .catch(() => {
            this.$refs.deleteModal.removeLoader();
          });
      }
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.searchObj.filter = "";
      this.getList(this.currentPage);
    },
    showDeletePopup(data) {
      this.$shareService.setMapValue("brand_page",this.currentPage);
      this.$refs.deleteModal.showModal(
        "Delete Brand",
        "Are you sure you want to delete " + data.name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "/brand/" + detail.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    onChange(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },

    uploadImage(id) {
      if (!this.file) {
        this.$router.push("/brand");
        return;
      }
      this.$api
        .uploadImageAPI({
          _action: "/brand/photo",
          _key: "image",
          _file: this.file,
          _body: {
            id: id,
          },
          _buttonId: "save-button",
        })
        .then((res) => {
          this.$refs.textModal.closeModal();
          this.getList(this.currentPage);
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.file = undefined;
        })
        .catch(() => { });
    },


    checkFeatured(data) {
      var obj = {};

      if (data.is_featured == 1) {
        obj.is_featured = 'Y';
      } else {
        obj.is_featured = 'N';
      }
      obj.id = data.id;
      obj.name = data.name;
      obj.description = data.description;
      obj.lang_code = 'EN';
      obj.list = data.list;
      this.$api
        .postAPI({
          _action: "/brand",
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getList(this.currentPage);
        })
        .catch(() => { });
    },
  },
};
</script>

