<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Product Attributes</h4>
          </div>
        </div>
        <div
          class="page-content"
          v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage"
        >
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 10px"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(currentPage)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                  />
                </div>
                <a
                  class="fill-btn btn-style cursor-pointer ml-3"
                  v-on:click="getList(currentPage)"
                  >Search</a
                >
                <a
                  class="fill-btn btn-style cursor-pointer"
                  v-on:click="resetFilter"
                  v-show="searchObj.keyword"
                  >Reset</a
                >
              </div>
              <div
                v-show="accessObject?.is_add == 1"
                style="cursor: pointer"
                class="col-lg-6 col-sm-6 txt-right form-group"
              >
                <a
                  v-on:click="SaveCategory()"
                  class="fill-btn cursor-pointer"
                  v-if="categoryId"
                  id="category-save-button"
                  >Save</a
                >

                <a
                  v-on:click="showPracticePopup()"
                  class="fill-btn btn-style cursor-pointer"
                  data-toggle="modal"
                  >Import</a
                >
                <a
                  class="fill-btn btn-style cursor-pointer"
                  v-on:click="showAddCategoryPopup"
                  >+ Add</a
                >
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th
                            class="sorting"
                            id="id-title"
                            v-on:click="sorting('title', 'id-title')"
                          >
                            Title
                          </th>
                          <th
                            class="sorting"
                            id="id-type"
                            v-on:click="sorting('type', 'id-type')"
                          >
                            <div style="width: 90px">Type</div>
                          </th>
                          <th v-show="categoryId">
                            <input
                              type="checkbox"
                              id="deleteAll"
                              v-show="categoryId"
                              v-on:click="selectAllData($event)"
                              true-value="1"
                              false-value="0"
                              class="mr-2"
                              v-model="is_select"
                              :class="is_select == '0' ? 'a' : 'b'"
                            />
                            Select
                          </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td style="text-align: left" v-if="!type">
                            {{ (currentPage - 1) * itemsPerPage + index + 1 }}
                          </td>
                          <td style="text-align: left" v-else>
                            {{ index + 1 }}
                          </td>
                          <td style="text-align: left">
                            {{ data.title }}
                          </td>
                          <td>{{ data.type }}</td>
                          <td v-show="categoryId">
                            <input
                              type="checkbox"
                              :id="'select_' + data.id"
                              v-show="categoryId"
                              v-on:click="selectData($event, data)"
                              v-model="data.is_select"
                              true-value="1"
                              false-value="0"
                              class="mr-2"
                              :class="data.is_select == '1' ? 'a' : 'b'"
                            />
                          </td>
                          <td>
                            <a
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="goToOptionPage(data)"
                              >Options</a
                            >
                            <a
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="showUpdateCategoryPopup(index)"
                              v-show="accessObject?.is_edit == 1 && !this.type"
                              >Edit</a
                            >
                            <a
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="showDeletePopup(data)"
                              v-show="accessObject?.is_delete == 1"
                              >Delete</a
                            > 
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4" v-if="!this.type">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList()" ref="errorComponent" />
                <TextModal ref="textModal">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      {{ details.id ? "Edit" : "Add" }} Product Attribute
                    </h5>
                    <button
                      type="button"
                      class="close"
                      v-on:click="$refs.textModal.closeModal"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <Form ref="categoryForm" @submit="save">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-lg-12 col-sm-12">
                          <div class="form-group">
                            <label>Title</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              class="form-control"
                              name="title"
                              v-model="details.title"
                              placeholder="Title"
                              rules="required:title"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="title" class="text-danger" />
                          </div>
                        </div>

                        <div
                          class="col-lg-6 col-sm-6"
                          v-for="(data, index) in languageList"
                          :key="index"
                        >
                          <div class="form-group">
                            <label
                              >Attribute Title ({{ data.code
                              }}<img
                                :src="data.flag ? data.flag : '/img/placeholder.png'"
                                style="width: 20px; height: 17px"
                              />)</label
                            >
                            <Field
                              type="text"
                              class="form-control"
                              :name="'name_' + data.code"
                              v-model="data.value"
                              :placeholder="'Attribute Title (' + data.code + ')'"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              :name="'name_' + data.code"
                              class="text-danger"
                            />
                          </div>
                        </div>

                        <div class="col-lg-12 col-sm-12">
                          <label for="inputRole"> Type</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="select"
                            aria-describedat=""
                            class="form-control"
                            id="inputRole"
                            name="name_role"
                            v-model="details.type"
                            rules="required:type"
                          >
                            <option value="">Select Type</option>
                            <option value="COLOR_PICKER">Color Picker</option>
                            <option value="DROPDOWN">Dropdown</option>
                          </Field>
                          <ErrorMessage name="name_role" class="text-danger" />
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button class="fill-btn" id="save-button">Save</button>
                      <button
                        type="button"
                        class="fil-btn"
                        id="cancel-button"
                        v-on:click="$refs.textModal.closeModal"
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                </TextModal>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-center"
          style="margin-top: 20%"
          v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage"
        >
          <h3>You do not have access to view product attributes</h3>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
    <TextModal ref="ImportModal">
      <div class="modal-header">
        <h5 class="modal-title">Import Attribute</h5>
        <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-lg-12 col-sm-12 mb-3">
          <div class="text-center mb-4">
            <!-- <div class="form-group">
            <Field
              as="select"
              class="form-control"
              id="inputRole"
              name="name_import_type"
              v-model="import_type"
            >
              <option value="">Select Type</option>
              <option value="PRODUCT">Product</option>
              <option value="CURRENCY">Currency</option>
              <option value="ATTRIBUTE">Attribute</option>
            </Field>
          </div> -->

            <div class="form-group">
              <Field
                as="select"
                class="form-control"
                id="inputRole"
                name="name_language"
                v-model="importLangCode"
                rules="required:language"
              >
                <option value="">Select Language</option>
                <option
                  v-for="(data, index) in languageList"
                  :key="index"
                  :value="data.code"
                >
                  {{ data.title }} ({{ data.code }})
                </option>
              </Field>
              <ErrorMessage name="name_language" class="text-danger" />
            </div>

            <div class="input-group mb-3" v-if="import_type">
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  id="inputGroupFile04"
                  v-on:change="onChange($event)"
                />
                <label
                  class="custom-file-label"
                  style="text-align: left"
                  for="inputGroupFile04"
                  >{{ docFile ? docFile.name : "Choose File" }}</label
                >
              </div>
            </div>
            <p v-if="import_type">
              <a href="" v-on:click="downloadTemplate">Download Template </a> file and
              fill with the items information.
            </p>
            <div id="msg"></div>
          </div>
        </div>
      </div>
      <div class="modal-footer" v-if="import_type">
        <button
          type="button"
          id="cancle-button"
          class="btn btn-secondary position-relative w-auto px-4"
          data-dismiss="modal"
          v-on:click="$refs.textModal.closeModal"
        >
          Close
        </button>
        <button
          v-on:click="uploadDocument()"
          id="document-button"
          type="button"
          class="btn btn-primary position-relative w-auto mt-0 mb-0 px-4"
        >
          Import
        </button>
      </div>
    </TextModal>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import TextModal from "@/components/TextModal";
import Pagination from "@/components/Pagination";
import Report from "../../Report";
export default {
  name: "CategoryPage",
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
    DeleteModal,
    TextModal,
    Pagination,
  },
  data() {
    return {
      categoryId: this.$route.params.id,
      type: this.$route.params.type,
      accessObject: this.$helperService.getAccessLevel("Manage Products"),
      loader: true,
      isShowAccessDeniedMessage: false,
      offset: -1,
      currentPage: 1,
      itemsPerPage: 10,
      searchObj: {
        keyword: "",
      },
      details: {
        title: "",
        type: "",
      },
      list: [],
      selectedList: [],
      is_select: "0",
      languageList: [],
      import_type: "ATTRIBUTE",
    };
  },
  mounted() {
    if (!this.accessObject) {
      this.isShowAccessDeniedMessage = true;
      this.$router.push("/access-denied");
      return;
    }
    this.getlanguageList();
    var tabChangePage = this.$shareService.getMapValue("product_attribute");
    this.getList(tabChangePage);
  },
  methods: {
    getlanguageList() {
      this.$api
        .getAPI({
          _action: "/language/all",
        })
        .then((res) => {
          this.languageList = res;
          for (var i = 0; i < this.languageList.length; i++) {
            this.languageList[i].value = "";
          }
        })
        .catch(() => {});
    },
    selectAllData(event) {
      if (event.target.checked) {
        for (var i = 0; i < this.list.length; i++) {
          this.selectedList.push(this.list[i].id);
          this.list[i].is_select = "1";
        }
      } else {
        this.selectedList = [];
        for (var j = 0; j < this.list.length; j++) {
          this.list[j].is_select = "0";
        }
      }
    },
    selectData(event, data) {
      if (event.target.checked) {
        this.selectedList.push(data.id);
      } else {
        for (var i = 0; i < this.selectedList.length; i++) {
          if (this.selectedList[i] == data.id) {
            this.selectedList.splice(i, 1);
          }
        }
      }

      setTimeout(() => {
        this.is_select = "1";
        for (var j = 0; j < this.list.length; j++) {
          if (this.list[j].is_select == "0") {
            this.is_select = "0";
            break;
          }
        }
      }, 1000);
    },
    SaveCategory() {
      var url = "";
      if (this.type == "brand") {
        url = "/brand-attribute/" + this.categoryId;
      } else {
        url = "/category-attribute/" + this.categoryId;
      }
      this.$api
        .postAPI({
          _action: url,
          _body: { list: this.selectedList },
          _buttonId: "category-save-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$router.go(-1);
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.$shareService.setMapValue("product_attribute_item_limit", this.itemsPerPage);
      this.getList(this.currentPage);
    },
    getList(page, orderBy, sortBy) {
      let itemLimit = this.$shareService.getMapValue("product_attribute_item_limit");
      if (itemLimit != null) {
        this.itemsPerPage = itemLimit;
      }
      if (page == null) {
        page = this.currentPage;
      }
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      this.searchObj.limit = this.itemsPerPage;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      var url = "/attributes";
      if (this.type && this.type == "brand") {
        this.searchObj.brand_id = this.categoryId;
        this.searchObj.category_id = "";
        this.searchObj.type = this.type;
        url = "/all-attributes";
      } else if (this.type && (this.type == "category" || this.type == "sub-category")) {
        this.searchObj.category_id = this.categoryId;
        this.searchObj.brand_id = "";
        this.searchObj.type = this.type;
        url = "/all-attributes";
      } else {
        this.searchObj.category_id = "";
        this.searchObj.brand_id = "";
        this.searchObj.type = "";
      }
      this.$api
        .getAPI({
          _action: url,
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;

          if (!this.type) {
            this.offset = res.next_offset;
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count, this.itemsPerPage);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No product attributes available");
            this.$refs.errorComponent.updateLoader(false);
          }

          this.is_select = "1";
          for (var j = 0; j < this.list.length; j++) {
            if (this.list[j].is_select == "0") {
              this.is_select = "0";
              break;
            }
          }
          this.selectedList = [];
          for (var i = 0; i < this.list.length; i++) {
            if (this.list[i].is_select == "1") {
              this.selectedList.push(this.list[i].id);
            }
          }

          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(this.currentPage, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(this.currentPage);
    },
    showAddCategoryPopup() {
      this.details = {};
      this.$refs.textModal.showModal();
      this.$refs.categoryForm.resetForm();
    },
    showUpdateCategoryPopup(index) {
      this.$shareService.setMapValue("product_attribute", this.currentPage);
      this.details = {};
      this.details.title = this.list[index].title;
      this.details.id = this.list[index].id;
      this.details.type = this.list[index].type;

      if (this.list[index].list.length > 0) {
        for (var i = 0; i < this.languageList.length; i++) {
          for (var j = 0; j < this.list[index].list.length; j++) {
            if (this.languageList[i].code == this.list[index].list[j].lang_code) {
              this.languageList[i].value = this.list[index].list[j].value;
            }
          }
        }
      } else {
        for (var k = 0; k < this.languageList.length; k++) {
          this.languageList[k].value = "";
        }
      }
      this.$refs.textModal.showModal();
    },
    save() {
      var obj = {
        title: this.details.title,
        type: this.details.type,
        list: this.languageList,
      };
      if (this.details.id) {
        (obj.id = this.details.id),
          this.$api
            .putAPI({
              _action: "/attribute/" + this.details.id,
              _body: obj,
              _buttonId: "save-button",
            })
            .then((res) => {
              this.$refs.textModal.closeModal();
              this.getList(this.currentPage);
              this.$notify({
                type: "success",
                text: res.message,
              });
            })
            .catch(() => {
              this.$refs.deleteModal.removeLoader();
            });
      } else {
        this.$api
          .postAPI({
            _action: "/attribute",
            _body: obj,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$refs.textModal.closeModal();
            this.getList(this.currentPage);
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {
            this.$refs.deleteModal.removeLoader();
          });
      }
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Product Attribute",
        "Are you sure you want to delete " + data.title,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "/delete-attribute/" + detail.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    showPracticePopup() {
      this.getlanguageList();
      this.$refs.ImportModal.showModal();
    },
    uploadDocument() {
      if (!this.importLangCode) {
        this.$notify({
          type: "error",
          text: "Please select language",
        });
        return false;
      }

      if (!this.import_type) {
        this.$notify({
          type: "error",
          text: "Please select import type",
        });
        return false;
      }

      if (this.docFile) {
        this.$api
          .uploadImageAPI({
            _action: "/import/attribute",
            _key: "file",
            _file: this.docFile,
            _body: { lang: this.importLangCode },
            _buttonId: "document-button",
          })
          .then((res) => {
            this.importLangCode = "EN";
            this.docFile = null;
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.ImportModal.closeModal();
          });
      } else {
        this.$refs.ImportModal.closeModal();
      }
    },
    downloadTemplate() {
      var attribute_url = "attribute-file.xlsx";
      window.open(Report.REPORT_URL + "/" + attribute_url);
    },
    onChange(e) {
      let files = e.target.files;
      // if (
      //   files[0].type.toLowerCase() != "application/pdf" &&
      //   files[0].type.toLowerCase() != "application/doc"
      // ) {
      //   alert("Invalid file formate, please use pdf file.");
      //   return;
      // }
      // const size = (files[0].size / 1024 / 1024).toFixed(2);
      // if (size > 10) {
      //   alert("File must be less then 10 MB");
      //   return;
      // }
      var tmppath = URL.createObjectURL(files[0]);
      this.docFile = files[0];
      document.getElementById("inputGroupFile04").src = tmppath;
      console.log(this.docFile);
    },
    goToOptionPage(data) {
      this.$router.push("/option/" + data.id);
      this.$shareService.setMapValue("product_attribute", this.currentPage);
    },
  },
};
</script>

