<template>
     <div v-show="isShowModal">
      <div class="spx-modal h-100 justify-content-center">
        <div class="spx-modal-body mx-auto" >
            <div class="modal-header pt-0">
            <h5 class="modal-title">{{title}}</h5>
            <button type="button" class="close" v-on:click="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{body}} ?</p>
          </div>
          <div class="modal-footer pb-0">
           <button type="button" class="fill-btn" id="delete-button" :disabled="showLoader" style="width: 18%; border-radius: 5px;"  v-on:click="onClick">
                <i v-if="showLoader" class='fa fa-spinner fa-spin'></i>Yes
            </button>
           <button type="button" class="fil-btn" id="cancel-button" style="width: 18%; border-radius: 5px;" v-on:click="closeModal">No</button> 
          </div>
        </div>
     </div>
     <div class="spx-modal-background"></div>
  </div>
</template>
<script>
    export default {
        name: "DeleteModal",
        data() {
            return {
                isShowModal: false,
                showLoader: false,
                title: 'Alert',
                body: 'Are you sure you want to delete',
                obj: {}
            }
        },
        mounted() {},
        methods: {
            showModal(title, body, obj) {
                this.title = title
                this.body = body
                this.obj = obj
                this.isShowModal = true
            },
            closeModal() {
                this.isShowModal = false
                this.removeLoader()
            },
            onClick() {
                this.$emit('remove', this.obj)
                this.showLoader = true
            },
            removeLoader() {
                this.showLoader = false
            }
        }
    }
</script>