<template>
  <div class="page-wrapper">
    <div class="page-content-wrapper">
      <div class="shadow-btm">
        <div class="row">
          <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
            <a
              class="pr-2 text-dark cursor-pointer"
              v-on:click="$router.push('/customer')"
              ><span class="font-24 mr-2"
                ><img src="/images/back-arrow.svg" width="18"
              /></span>
            </a>
            <h4 class="mb-0 lg-bld">Posts</h4>
          </div>
        </div>
      </div>
      <div class="page-content">
        <div class="card-body py-0 px-0">
          <ul class="nav nav-pills">
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/edit-customer/' + this.id)"
              >
                <span>Basic Details</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/post-review/' + this.id)"
              >
                <span>Reviews</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/like/' + this.id)"
              >
                <span>Likes</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/shared/' + this.id)"
              >
                <span>Shared Products</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/customer-requested/' + this.id)"
              >
                <span>Requested Products</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/activity-log/' + this.id)"
              >
                <span>Activity Log</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                :class="{ active: currentTab == 'POST' }"
                v-on:click="changeTab('POST')"
              >
                <span>Posts</span></a
              >
            </li>
            
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  v-on:click="$router.push('/favorite/' + this.id)"
                >
                  <span>Favorite</span></a
                >
              </li>
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  v-on:click="$router.push('/following/' + this.id)"
                >
                  <span>Following</span></a
                >
              </li>
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  v-on:click="$router.push('/follower/' + this.id)"
                >
                  <span>Follower</span></a
                >
              </li>
          </ul>
          <div class="row mt-3">
            <div class="col-lg-6 col-sm-6 form-group row">
              <div class="has-search ml-3">
                <i
                  class="fa fa-search"
                  style="position: absolute; margin: 10px"
                  aria-hidden="true"
                ></i>
                <input
                  type="text"
                  class="form-control"
                  v-on:keyup.enter="getList(currentPage)"
                  placeholder="Search"
                  v-model="searchObj.keyword"
                />
              </div>
              <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getList(currentPage)"
                >Search</a
              >
              <a
                class="fill-btn btn-style cursor-pointer"
                v-on:click="resetFilter"
                v-show="searchObj.keyword"
                >Reset</a
              >
              
            </div>
            <div class="col-lg-6 col-sm-6 form-group  d-flex justify-content-end">
              <a
                  v-if="currentTabBottom == 'ACTIVE' && deleteList.length > 0"
                  class="fill-btn cursor-pointer btn-style"
                  style="padding: 11px; margin-right: 16px"
                  v-on:click="deleteAllPopup"
                  >Delete
                </a>
            </div>
          </div>
          <div class="card border mb-0 radius-15">
            <div class="card-body p-0 pb-4">
              <ul class="nav nav-pills m-3">
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  v-on:click="setStatus('ACTIVE')"
                  :class="{ active: currentTabBottom == 'ACTIVE' }"
                >
                  <span>Active</span></a>
              </li>
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  v-on:click="setStatus('DELETED')"
                  :class="{ active: currentTabBottom == 'DELETED' }"
                >
                  <span>Deleted</span></a
                >
              </li>
            </ul>
              <div class="table-responsive">
                <div class="dataTables_wrapper dt-bootstrap4">
                  <table
                    class="table table-striped table-bordered dataTable"
                    style="width: 100%"
                    role="grid"
                    aria-describedby="example_info"
                  >
                    <thead>
                      <tr role="row">
                        <th>Sr. No.</th>
                        <th
                            class="sorting"
                            id="id-title"
                            v-on:click="sorting('posts.title', 'id-title')"
                          >
                            Title
                          </th>
                          <th
                            class="sorting"
                            id="id-category_id"
                            v-on:click="sorting('categories.name', 'id-category_id')"
                          >
                            Category
                          </th>
                          <th  class="sorting"
                            id="id-user_id"
                            v-on:click="sorting('users.name', 'id-user_id')"
                          >
                            Created By
                          </th>
                          <th  class="sorting"
                            id="id-created_at"
                            v-on:click="sorting('posts.created_at', 'id-created_at')"
                          >
                            Created At
                          </th>
                          <th
                          >
                            Total Like 
                          </th>
                          <th
                          >
                            Total Reply
                          </th>
                          <th
                            v-if="currentTabBottom == 'ACTIVE'"
                          >
                            <input
                              type="checkbox"
                              id="deleteAll"
                              v-on:click="selectAllData($event)"
                              true-value="1"
                              false-value="0"
                              class="mr-2"
                              v-model="is_select"
                              :class="is_select == '0' ? 'a' : 'b'"
                            />
                            Select
                          </th>
                          <th
                          >
                            Action
                          </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in list" :key="index">
                          <td style="text-align: left">
                            {{ ($refs.listPagination.currentPage - 1) * 10 + index + 1 }}
                          </td>
                          <td style="text-align: left">{{ data.title }}</td>
                          <td style="text-align: left">
                            {{ data?.category?.name }}
                          </td>
                          <td style="text-align: left">
                            {{ data?.user?.name }}
                          </td>
                          <td style="text-align: left">
                            {{ $helperService.getFormatedDateTime(data?.created_at) }}
                          </td>

                          <td style="text-align: left">
                            {{ data?.like_count }}
                          </td>
                          <td style="text-align: left">
                            {{ data?.reply_count }}
                          </td>
                          <td
                            v-if="currentTabBottom == 'ACTIVE'"
                          >
                            <input
                              type="checkbox"
                              :id="'delete_' + data.id"
                              v-on:click="deleteData($event, data)"
                              v-model="data.is_select"
                              true-value="1"
                              false-value="0"
                              class="mr-2"
                              :class="data.is_select == '1' ? 'a' : 'b'"
                            />
                          </td>
                          <td
                          >
                            <a
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="$router.push('/view-comments/' + data?.id)"
                              >View Comments</a
                            >
                            <a
                              v-if="currentTabBottom == 'ACTIVE'"
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="$router.push('/edit-post/' + data.slug)"
                              >Edit</a
                            >
                            <a
                              v-if="currentTabBottom == 'ACTIVE'"
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="deleteSinglePopup(data?.id)"
                              >Delete</a
                            >
                            <a
                              v-if="currentTabBottom == 'DELETED'"
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="showConfirmation('ACTIVE',data?.id)"
                              >Restore</a
                            >
                            <a
                              v-if="currentTabBottom == 'DELETED'"
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="showConfirmation('DELETED',data?.id)"
                              >Permanent Delete</a
                            >
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row mx-0 mb-4">
                <div class="col-lg-12 mt-5 text-center">
                  <div class="page-bottom-pagination text-right">
                    <Pagination
                      @page-change="pageChange"
                      @items-per-page-change="itemsPerPageChange"
                      ref="listPagination"
                    />
                  </div>
                </div>
              </div>
              <ErrorComponent @retry="getList(currentPage)" ref="errorComponent" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <DeleteModal @remove="deleteAllRecord" ref="deleteModal" />
    <DeleteModal @remove="deleteSingleRecord" ref="deleteSingleModal" />
    <TextModal ref="confirmation">
      <div class="modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="close" v-on:click="closeConfirmation()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-sm-12 mb-3">
              Are you sure you want to <span v-if="action_status == 'ACTIVE'">restore post?</span> <span v-else>permanent delete post?</span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="fill-btn" @click="updateStatusApiCall()" id="confirmation-btn-new">Yes</button>
          <button
            type="button"
            class="fil-btn"
            id="cancel-button"
            v-on:click="closeConfirmation()"
          >
            Cancel
          </button>
        </div>
    </TextModal>
</template>
<script>
import TextModal from "@/components/TextModal";
import DeleteModal from "@/components/DeleteModal";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
export default {
  name: "ActivityLog",
  components: {
    ErrorComponent,
    Pagination,
    TextModal,
    DeleteModal,
  },
  data() {
    return {
      id: this.$route.params.id,
      currentTab: "",
      currentTabBottom: "ACTIVE",
      loader: true,
      itemsPerPage: 10,
      is_select: "0",
      confirmation_id: null,
      action_status:null,
      deleteList:[],
      searchObj: {
        keyword: "",
        status:"ACTIVE"
      },
      offset: -1,
      list: [],
      currentPage:1
    };
  },
  mounted() {
    this.getCategory();
    this.setStatus("ACTIVE");
    this.changeTab("POST");
  },
  methods: {
    pageChange(offset) {
      this.getList(offset);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.$shareService.setMapValue("post_item_limit", this.itemsPerPage);
      this.getList(this.currentPage);
    },
    setStatus(status) {
      this.searchObj.status = status;
      this.currentTabBottom = status;
      this.getList(1);
    },
    getList(page, orderBy, sortBy) {
      let itemLimit = this.$shareService.getMapValue("post_item_limit");
      if (itemLimit != null) {
        this.itemsPerPage = itemLimit;
      }
      if (page == null) {
        page = this.currentPage;
      }
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      this.searchObj.limit = this.itemsPerPage;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/post/" + this.id,
          _body: this.searchObj,
        })
        .then((res) => {
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count,this.itemsPerPage);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Posts available");
            this.$refs.errorComponent.updateLoader(false);
          }
          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(this.currentPage, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(this.currentPage);
    },
    changeTab(tab) {
      this.currentTab = tab;
      this.currentTabBottom = "ACTIVE";
      if (tab == "POST") {
        this.$router.push("/post/" + this.id);
      }
    },
    getCategory() {
        this.$api
          .getAPI({
            _action: "/top-menu/category/listv2",
            _body: { lang: this.lang_code },
          })
          .then((res) => {
            this.catgeories = res;
          })
          .catch(() => {});
    },
   
    deleteAllPopup() {
      this.$shareService.setMapValue("post_page",this.currentPage);
      this.$refs.deleteModal.showModal(
        "Delete Post",
        "Are you sure you want to delete " + this.deleteList.length,
        +" admins"
      );
    },
    deleteAllRecord() {
      this.$shareService.setMapValue("post_page",this.currentPage);
      this.$api
        .postAPI({
          _action: "/delete/post-all",
          _body: { list: this.deleteList },
        })
        .then((res) => {
          if (res) {
            this.deleteList = [];
            this.is_select = "0";

            this.$refs.deleteModal.closeModal();
            this.getList(this.currentPage);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    deleteSinglePopup(id) {
      this.$shareService.setMapValue("post_page",this.currentPage);
      this.delete_id = id;
      this.$refs.deleteSingleModal.showModal(
        "Delete Post",
        "Are you sure you want to delete",
      );
    },
    deleteSingleRecord() {
      this.$api
        .deleteAPI({
          _action: "delete-post/" + this.delete_id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteSingleModal.closeModal();
            this.delete_id = null;
            this.getList(this.currentPage);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },

    deleteData(event, data) {
      if (event.target.checked) {
        this.deleteList.push(data.id);
      } else {
        for (var i = 0; i < this.deleteList.length; i++) {
          if (this.deleteList[i] == data.id) {
            this.deleteList.splice(i, 1);
          }
        }
      }

      setTimeout(() => {
        this.is_select = "1";
        for (var j = 0; j < this.list.length; j++) {
          if (this.list[j].is_select == "0") {
            this.is_select = "0";
            break;
          }
        }
      }, 1000);
    },
    selectAllData(event) {
      if (event.target.checked) {
        for (var i = 0; i < this.list.length; i++) {
          this.deleteList.push(this.list[i].id);
          this.list[i].is_select = "1";
        }
      } else {
        this.deleteList = [];
        for (var j = 0; j < this.list.length; j++) {
          this.list[j].is_select = "0";
        }
      }
    },
    showConfirmation(status,confirmation_id) {
      this.$shareService.setMapValue("post_page",this.currentPage);
      this.action_status = status;
      this.confirmation_id = confirmation_id;
      this.details = {};
      this.$refs.confirmation.showModal();
    },
    closeConfirmation(){
      this.$shareService.setMapValue("post_page",this.currentPage);
      this.$refs.confirmation.closeModal();
      this.action_status = null;
      this.confirmation_id = null;
    },
    updateStatusApiCall() {
      this.$api
        .postAPI({
          _action: "/update-status-post/" + this.confirmation_id,
          _body:{status:this.action_status},
          _buttonId:"confirmation-btn-new"
        })
        .then((res) => {
          if (res) {
            this.closeConfirmation();
            this.getList(this.currentPage);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
  },
};
</script>
