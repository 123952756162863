<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a class="pr-2 text-dark cursor-pointer" v-on:click="$router.push('/admin')"><span
                  class="font-24 mr-2"><img src="/images/back-arrow.svg" width="18" /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Admin</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Name</label>
                            <span class="text-danger">*</span>
                            <Field type="text" name="user_name" class="form-control" placeholder="Name"
                              v-model="detail.name" rules="required:name" :validateOnInput="true" />
                            <ErrorMessage name="user_name" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Email</label>
                            <span class="text-danger">*</span>
                            <Field type="email" name="email" class="form-control" placeholder="Email"
                              v-model="detail.email" rules="required:email|email" :validateOnInput="true" />
                            <ErrorMessage name="email" class="text-danger" />
                            <div data-toggle="modal" data-target="#changePassword" style="
                                float: right;
                                cursor: pointer;
                                text-decoration: underline;
                              " v-show="detail.id" v-on:click="showChangePasswordPopup">
                              <a>Change Password</a>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Mobile number</label>

                            <Field type="text" name="phone" placeholder="Phone" v-slot="{ field }"
                              v-model="detail.mobile_number" rules=":contact number|phone"
                              :validateOnInput="true">
                              <PhoneCode id="mobile" placeholder="Mobile Number" v-model:countryCode="
                                detail.country_code
                              " v-model="detail.mobile_number" v-model:dialCode="detail.dial_code" v-bind="field" />
                              <ErrorMessage name="phone" class="text-danger" />
                            </Field>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6" v-if="!id">
                          <div class="form-group">
                            <label>Password </label>
                            <span class="text-danger">*</span>
                            <!-- <Field type="password" name="password" class="form-control" placeholder="Password"
                              v-model="detail.password" rules="required:password|minLength:6" :validateOnInput="true" /> -->
                            <Field :type="passwordFieldType" name="password" class="form-control" placeholder="Password"
                              rules="required:password|minLength:6" :validateOnInput="true" v-model="detail.password" />
                            <span style="
                                position: absolute;
                                right: 20px;
                                top: 40px;
                                z-index: 999;
                              ">
                              <a class="psw-icon cursor-pointer" v-on:click="switchVisibility"><img :src="image"
                                  width="20" /></a>
                            </span>
                            <ErrorMessage name="password" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6" v-if="!id">
                          <div class="form-group">
                            <label>Confirm Password</label>
                            <span class="text-danger">*</span>
                            <!-- <Field type="password" placeholder="Confirm Password" name="name_ccpassword"
                              class="form-control" rules="required:confirm password|confirmed:@password"
                              :validateOnInput="true" /> -->
                            <Field :type="passwordFieldType2" placeholder="Confirm Password" name="name_ccpassword"
                              class="form-control" rules="required:confirm password|confirmed:@password"
                              :validateOnInput="true" />
                            <span style="
                                position: absolute;
                                right: 20px;
                                top: 40px;
                                z-index: 999;
                              ">
                              <a class="psw-icon cursor-pointer" v-on:click="switchPasswordVisibility"><img :src="image"
                                  width="20" /></a>
                            </span>
                            <ErrorMessage name="name_ccpassword" class="text-danger" />
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <label for="inputRole"> Role</label>
                          <span class="text-danger">*</span>
                          <Field as="select" aria-describedat="" class="form-control" id="inputRole" name="name_role"
                            v-model="detail.role_id" rules="required:role">
                            <option value="">Select Role</option>
                            <option v-for="(data, index) in roleList" :key="index" :value="data.id">
                              {{ data.name }}
                            </option>
                          </Field>
                          <ErrorMessage name="name_role" class="text-danger" />
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                          <button type="button" class="fil-btn px-4 ml-3" v-on:click="$router.go(-1)"
                            id="cancel-button">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TextModal ref="textModal">
      <div class="modal-header pt-0">
        <h5 class="modal-title">Change Password</h5>
        <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form ref="changePasswordForm" @submit="changePassword">
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>New Password</label>
            <span class="text-danger">*</span>
            <Field :type="passwordFieldType" name="name_password" class="form-control" placeholder="New Password"
              v-model="password" rules="required:password|minLength:6" :validateOnInput="true" />
            <span style="position: absolute; right: 22px; top: 40px; z-index: 999">
              <a class="psw-icon cursor-pointer" v-on:click="switchVisibility"><img :src="image" width="20" /></a>
            </span>
            <ErrorMessage name="name_password" class="text-danger" />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>Confirm Password</label>
            <span class="text-danger">*</span>
            <Field :type="passwordFieldType2" placeholder="Confirm Password" name="name_ccpassword" class="form-control"
              rules="required:confirm password|confirmed:@name_password" :validateOnInput="true" />
            <span style="
                                position: absolute;
                                right: 20px;
                                top: 40px;
                                z-index: 999;
                              ">
              <a class="psw-icon cursor-pointer" v-on:click="switchPasswordVisibility"><img :src="images"
                  width="20" /></a>
            </span>
            <ErrorMessage name="name_ccpassword" class="text-danger" />
          </div>
        </div>
        <div class="modal-footer pb-0">
          <button class="fill-btn" id="change-password-button" style="width: 20%; border-radius: 5px">
            Save
          </button>
          <button type="button" class="fil-btn" id="cancel-button" v-on:click="$refs.textModal.closeModal"
            style="width: 20%; border-radius: 5px">
            Cancel
          </button>
        </div>
      </Form>
    </TextModal>
  </div>
</template>
<script>
import TextModal from "@/components/TextModal";
import { Form, Field, ErrorMessage } from "vee-validate";
import PhoneCode from "../../components/PhoneCodePicker/PhoneCode.vue";
export default {
  name: "AddAdmin",
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
    PhoneCode,
  },
  data() {
    return {
      id: this.$route.params.id,
      roleList: [],
      detail: {
        role_id: "",
        password: "",
        confirm_password: "",
      },
      passwordFieldType: "password",
      passwordFieldType2: "password",
      image: "/images/show.png",
      images: "/images/show.png"

    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    } else {
      this.getRoleList();
    }
  },
  methods: {
    getRoleList() {
      this.$api
        .getAPI({
          _action: "/role/list",
        })
        .then((res) => {
          this.roleList = res;
          if (this.detail.role_id != null) {
            this.role_id = this.detail.role_id;
          }
        })
        .catch(() => { });
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/admin/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;
          this.getRoleList();
        })
        .catch(() => { });
    },
    save() {
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/edit/admin",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => { });
      } else {
        this.$api
          .postAPI({
            _action: "/admin/signup",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => { });
      }
    },
    showChangePasswordPopup() {
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    changePassword() {
      var obj = {};
      obj.password = this.password;
      obj.id = this.id;
      this.$api
        .putAPI({
          _action: "/change/password",
          _body: obj,
          _buttonId: "change-password-button",
        })
        .then(() => {
          this.$refs.textModal.closeModal();
        })
        .catch(() => { });
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    switchPasswordVisibility() {
      this.passwordFieldType2 =
        this.passwordFieldType2 === "password" ? "text" : "password";
      this.images =
        this.images === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
  },
};
</script>