<template>
  <div class="page-wrapper">
    <div class="page-content-wrapper">
      <div class="shadow-btm">
        <div class="row col-lg-6 col-sm-6 my-auto">
          <h4 class="mb-0 lg-bld">Notifications</h4>
        </div>
      </div>

      <div class="page-content">
        <div class="card border bg-white">
          <div class="card-header border mb-0 radius-15">
            <div class="">
              <div class="mb-0 lg-bld">
                New Notifications
              </div>
            </div>
          </div>
          <div class="card-body mb-0 radius-15" v-show="!loader">
            <div class="table table-striped">
              <div v-for="(data, index) in list" :key="index">
                <div :class="data.is_read==0?'notification-row' :'' ">
                  <div class="d-flex justify-content-between aligh-items-center mb-2 border-bottom pb-2">
                    <div>
                      <p class="mb-0">{{ data.title }}</p>
                      <p class="small mb-0 text-secondary">{{ $helperService.getFormatedDateTime(data.created_at) }}</p>
                    </div>
                    <button class="fill-btn" v-on:click="updateReadStatus(data)">View</button>
                  </div>
                </div>
              </div>
            </div>
            <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          </div>
        </div>
      </div>
      <div class="page-content">
        <div class="card border bg-white">
          <div class="card-header border mb-0 radius-15">
            <div class="">
              <div class="mb-0 lg-bld">
                Earlier Notifications
              </div>
            </div>
          </div>

          <div class="card-body mb-0 radius-15" v-show="!earlierLoader">
            <div v-for="(data, index) in earlierList" :key="index">
              <div :class="data.is_read==0?'notification-row' :'' " >
                <div class="d-flex justify-content-between aligh-items-center mb-2 border-bottom pb-2">
                  <div>
                    <p class="mb-0">{{ data.title }}</p>
                    <p class="small mb-0 text-secondary">{{ $helperService.getFormatedDateTime(data.created_at) }}</p>
                  </div>
                  <button class="fill-btn" v-show="accessObject?.is_view==1 " v-on:click="view(data)">View</button>
                </div>
              </div>
            </div>
          </div>
          <ErrorComponent @retry="getEarlierNotificationList(0)" ref="earlierErrorComponent" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";

export default {
  name: "ManageNotification",
  components: {
    ErrorComponent
  },
  data() {
    return {
      // type:"NEW_SIGN_UP",
      accessObject: this.$helperService.getAccessLevel("Notifications"),
      isShowAccessDeniedMessage: false,
      loader: true,
      earlierLoader: true,
      offset: -1,
      itemsPerPage: 10,searchObj: {
        keyword: "",
        type: ""
      },
      list: [],
      earlierList: []
    };
  },
  mounted() {
    if (!this.accessObject) {
      this.isShowAccessDeniedMessage = true;
      this.$router.push("/access-denied");
      return;
    }
    this.getList(0);
    this.getEarlierNotificationList(0);
  },
  methods: {
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page;
this.searchObj.limit = this.itemsPerPage;
      this.searchObj.type = this.type
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "admin/notification/in/24-hrs",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No notification available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    getEarlierNotificationList(page, orderBy, sortBy) {
      this.$refs.earlierErrorComponent.updateLoader(true);
      this.earlierList = [];
      this.searchObj.offset = page;
this.searchObj.limit = this.itemsPerPage;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "admin/notification/before/24-hrs",
          _body: this.searchObj,
        })
        .then((res) => {
          this.earlierLoader = false;
          this.earlierList = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.earlierErrorComponent) {
            this.$refs.earlierErrorComponent.setData(res, "No earlier notification available");
            this.$refs.earlierErrorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.earlierLoader = false;
          if (this.$refs.earlierErrorComponent) {
            this.$refs.earlierErrorComponent.setData(0, undefined, "");
            this.$refs.earlierErrorComponent.updateLoader(false);
          }
        });
    },
    view(data) {
      if (data.type == 'NEW_SIGN_UP') {
        this.$router.push('/edit-customer/' + data.reference_id)
      }
      else {
        this.$router.push('/view-requested/' + data.reference_id)


      }
    },
    updateReadStatus(data) {
      var obj = {}
      obj.id = data.id
      this.$api
        .putAPI({
          _action: "/update/notification/status",
          _body: obj
        })
        .then(() => {
          if (data.type == 'NEW_SIGN_UP') {
            this.$router.push('/edit-customer/' + data.reference_id)
          }
          else {
            this.$router.push('/view-requested-product/' + data.reference_id)


          }
        })
        .catch(() => { });
    }
  },
};
</script>