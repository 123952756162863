<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Admins</h4>
          </div>
        </div>
        <div class="page-content" v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i class="fa fa-search" style="position: absolute; margin: 10px" aria-hidden="true"></i>
                  <input type="text" class="form-control" v-on:keyup.enter="getList(currentPage)"placeholder="Search"
                    v-model="searchObj.keyword" />
                </div>
                <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getList(currentPage)">Search</a>
                <a class="fill-btn btn-style cursor-pointer" v-on:click="resetFilter"
                  v-show="searchObj.keyword">Reset</a>
              </div>
              <div style="cursor: pointer" class="col-lg-6 col-sm-6 txt-right form-group">
                <a class="fill-btn cursor-pointer" v-show="accessObject?.is_add == 1"
                  style="padding: 11px; margin-right: 16px" v-on:click="$router.push('/add-admin')">+ Add</a>
                <a class="fill-btn cursor-pointer" v-show="accessObject?.is_delete == 1"
                  style="padding: 11px; margin-right: 16px" v-on:click="deleteAllPopup">Delete
                </a>
              </div>

            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table class="table table-striped table-bordered dataTable" style="width: 100%" role="grid"
                      aria-describedby="example_info">
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th class="sorting" id="id-name" v-on:click="sorting('name', 'id-name')">Name</th>
                          <th class="sorting" id="id-email" v-on:click="sorting('email', 'id-email')">Email</th>
                          <th class="sorting" id="id-mobile" v-on:click="sorting('mobile', 'id-mobile')">Mobile</th>
                          <th v-show="accessObject?.is_delete == 1">
                            <input type="checkbox" id="deleteAll" v-show="accessObject?.is_delete == 1"
                              v-on:click="selectAllData($event)" true-value="1" false-value="0" class="mr-2"
                              v-model="is_select" :class="is_select == '0' ? 'a' : 'b'" /> Select
                          </th>
                          <th v-show="accessObject?.is_edit == 1">Action</th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td style="text-align:left;">
                            {{
                              (currentPage - 1) * itemsPerPage + index + 1
                            }}
                          </td>
                          <td style="text-align:left;">{{ data.name }}</td>
                          <td style="text-align:left;">{{ data.email }}</td>
                          <td style="text-align:left;">{{ data.mobile_number }}</td>
                          <td v-show="accessObject?.is_delete == 1">

                            <input type="checkbox" :id="'delete_' + data.id" v-show="accessObject?.is_delete == 1"
                              v-on:click="deleteData($event, data)" v-model="data.is_select" true-value="1"
                              false-value="0" class="mr-2" :class="data.is_select == '1' ? 'a' : 'b'" />
                          </td>
                          <td v-show="accessObject?.is_edit == 1">
                            <a class="fill-btn btn-style cursor-pointer"
                              v-on:click="goToEditPage(data)"
                              v-show="accessObject?.is_edit == 1">Edit</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                        ref="listPagination" />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
        <div class="text-center" style="margin-top: 20%"
          v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage">
          <h3>You do not have access to view admin</h3>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteAllRecord()" ref="deleteModal" />
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
export default {
  name: "AdminPage",
  components: {
    ErrorComponent,
    DeleteModal,
    Pagination,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage Admins"),
      loader: true,
      currentPage:1,
      isShowAccessDeniedMessage: false,
      itemsPerPage: 10, searchObj: {
        keyword: '',
      },
      offset: -1,
      list: [],
      deleteList: [],
      is_select: '0'
    };
  },
  mounted() {
    if (!this.accessObject) {
      this.isShowAccessDeniedMessage = true;
      this.$router.push("/access-denied");
      return;
    }
    var tabChangePage = this.$shareService.getMapValue("admin_page");
    this.getList(tabChangePage);
  },
  methods: {
    pageChange(offset) {
      this.getList(offset);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.$shareService.setMapValue("admin_item_limit", this.itemsPerPage);
      this.getList(this.currentPage);
    },
    getList(page, orderBy, sortBy) {

      let itemLimit = this.$shareService.getMapValue("admin_item_limit");
      if (itemLimit != null) {
        this.itemsPerPage = itemLimit;
      }
      if (page == null) {
        page = this.currentPage;
      }
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page - 1;
      this.currentPage = page;
      this.searchObj.limit = this.itemsPerPage;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "admin/pagination/list",
          _body: this.searchObj
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count,this.itemsPerPage);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No admin list available");
            this.$refs.errorComponent.updateLoader(false);
          }

          for (var i = 0; i < this.list.length; i++) {
            this.list[i].is_select = "0";
          }
          
          if (this.$refs.listPagination) {
            this.$refs.listPagination.currentPage = this.currentPage;
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id)
      this.getList(this.currentPage, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = ''
      this.getList(this.currentPage);
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Admin",
        "Are you sure you want to delete " + data.name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "/user/" + detail.uuid,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },

    deleteAllPopup() {
      this.$refs.deleteModal.showModal(
        "Delete Admins",
        "Are you sure you want to delete " + this.deleteList.length,
        + " admins"
      );
    },
    deleteAllRecord() {
      this.$api
        .postAPI({
          _action: "/delete/user-all",
          _body: { "list": this.deleteList }
        })
        .then((res) => {
          if (res) {
            this.deleteList = [];
            this.is_select = '0';

            this.$refs.deleteModal.closeModal();
            this.getList(this.currentPage);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    goToEditPage(data){
      this.$shareService.setMapValue("admin_page", this.currentPage);
      this.$router.push('/edit-admin/' + data.id);

    },
    deleteData(event, data) {
      this.$shareService.setMapValue("admin_page", this.currentPage);
      if (event.target.checked) {
        this.deleteList.push(data.id);
      } else {
        for (var i = 0; i < this.deleteList.length; i++) {
          if (this.deleteList[i] == data.id) {
            this.deleteList.splice(i, 1);
          }
        }
      }

      setTimeout(() => {
        this.is_select = "1";
        for (var j = 0; j < this.list.length; j++) {
          if (this.list[j].is_select == "0") {
            this.is_select = "0";
            break;
          }
        }
      }, 1000);


    },
    selectAllData(event) {
      if (event.target.checked) {
        for (var i = 0; i < this.list.length; i++) {
          this.deleteList.push(this.list[i].id);
          this.list[i].is_select = "1";
        }
      } else {
        this.deleteList = [];
        for (var j = 0; j < this.list.length; j++) {
          this.list[j].is_select = "0";
        }
      }
    },
  },
};
</script>
