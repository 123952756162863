<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/content')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">
                {{ $helperService.getTitleCase(type) }}
              </h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form @submit="save()">
                      <div class="row">
                        <div
                          class="col-lg-12 col-sm-6"
                          v-if="type == 'TERMS AND CONDITION'"
                        >
                          <editor
                            api-key="eo6qoh3g2lfaxguruvplyfgpudqfozg2dlylrvovh62e979f"
                            placeholder="Description"
                            v-model="details.description"
                          ></editor>
                        </div>
                        <div class="col-lg-12 col-sm-6" v-if="type == 'CONTACT US'">
                          <div class="form-group">
                            <label>Address</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="textarea"
                              name="address"
                              class="form-control"
                              placeholder="Address"
                              v-model="details.address"
                              rules="required:address"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="address" class="text-danger" />
                          </div>
                          <div class="form-group">
                            <label>Email</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="email"
                              name="email"
                              class="form-control"
                              placeholder="Email"
                              v-model="details.email"
                              rules="required:email|email"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="email" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-12 col-sm-6" v-if="type == 'ABOUT US'">
                          <editor
                            api-key="eo6qoh3g2lfaxguruvplyfgpudqfozg2dlylrvovh62e979f"
                            placeholder="Description"
                            v-model="details.description"
                          ></editor>
                        </div>
                        <div class="col-lg-12 col-sm-6" v-if="type == 'SOCIAL MEDIA'">
                          <div class="form-group">
                            <label>Email</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="email"
                              name="email"
                              class="form-control"
                              placeholder="Email"
                              v-model="details.email"
                              rules="required:email|email"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="email" class="text-danger" />
                          </div>
                          <div class="form-group">
                            <label>Facebook</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="facebook"
                              class="form-control"
                              placeholder="Facebook"
                              v-model="details.facebook"
                              rules=":facebook |facebook"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="facebook" class="text-danger" />
                          </div>
                          <div class="form-group">
                            <label>Instagram</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="instagram"
                              class="form-control"
                              placeholder="Instagram"
                              v-model="details.instagram"
                              rules=":instagram |instagram"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="instagram" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-12 col-sm-6" v-if="type == 'PRIVACY POLICY'">
                          <editor
                            api-key="eo6qoh3g2lfaxguruvplyfgpudqfozg2dlylrvovh62e979f"
                            placeholder="Description"
                            v-model="details.description"
                          ></editor>
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            id="cancel-button"
                            v-on:click="$router.go(-1)"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Editor from "@tinymce/tinymce-vue";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "AddContent",
  components: {
    Form,
    Field,
    ErrorMessage,
    Editor,
  },
  data() {
    return {
      type: this.$route.query.type,
      details: {},
    };
  },
  mounted() {
    if (this.type) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "/cms/list",
          _body: { keyword: this.type },
        })
        .then((res) => {
          if (res && res.length > 0) {
            this.details = res[0];
          }
        })
        .catch(() => {});
    },
    save() {
      this.$api
        .putAPI({
          _action: "/cms",
          _body: this.details,
          _buttonId: "save-button",
        })
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },
  },
};
</script>
