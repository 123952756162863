<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/snd-notification')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">Send Notifications</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form @submit="send()">
                      <div class="row">
                        <div class="col-lg-12 col-sm-12">
                          <div class="form-group">
                            <label>Title</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="title"
                              class="form-control"
                              v-model="details.title"
                              placeholder="Title"
                              rules="required:title"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="title" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-12 col-sm-12">
                          <div class="form-group">
                            <label>Text</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="textarea"
                              name="text"
                              class="form-control"
                              v-model="details.text"
                              placeholder="Text"
                              rules="required:text"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="text" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 d-flex">
                          <div class="form-group row ml-1">
                            <label class="mr-4">Send to</label>
                            <div class="mr-4">
                              <Field
                                type="radio"
                                id="all"
                                name="all"
                                value="1"
                                v-model="details.is_all"
                              />
                              <label for="all">All</label>
                            </div>
                            <div>
                              <Field
                                type="radio"
                                id="specificUser"
                                name="all"
                                value="2"
                                v-model="details.is_all"
                              />
                              <label for="specificUser">Specific User</label>
                            </div>
                          </div>
                          <div class="form-group ml-5">
                            <!-- <label class="mr-4">Country</label> -->
                            <div class="mr-4">
                              <Field
                                type="radio"
                                id="country"
                                name="all"
                                value="3"
                                v-model="details.is_all"
                                v-on:click="countryFlag"
                              />
                              <label for="country">Country</label>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-lg-12 col-sm-12"
                          v-show="details.is_all == '2'"
                        >
                          <div class="form-group">
                            <Multiselect
                              mode="tags"
                              trackBy="label"
                              label="label"
                              valueProp="id"
                              :searchable="true"
                              v-model="details.selected_user"
                              :options="userList"
                            />
                          </div>
                        </div>
                        <div
                          class="col-lg-12 col-sm-12"
                          v-show="details.is_all == '3'"
                        >
                          <div class="form-group">
                            <Multiselect
                              mode="tags"
                              :searchable="true"
                              name="countryMultiselect"
                              v-model="details.selected_user"
                              trackBy="name"
                              label="name"
                              valueProp="id"
                              :options="countryList"
                            />
                          </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Send
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "SendNotification",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  data() {
    return {
      specific: "",
      details: {
        is_all: 0,
        // is_country: "",
        selected_user: [],
        // country_id: []
      },
      userList: [],
      countryList: [],
    };
  },
  mounted() {
    this.getUserList();
    this.getCountryList();
  },
  methods: {
    getCountryList() {
      this.$api
        .getAPI({
          _action: "/country/list",
        })
        .then((res) => {
          this.countryList = res;
          console.log(this.countryList);
          // if (this.detail.country_id != null) {
          //   this.country_id = this.detail.country_id;
          // }
        })
        .catch(() => {});
    },
    getUserList() {
      this.$api
        .getAPI({
          _action: "/customer/list",
        })
        .then((res) => {
          for (var i in res) {
            res[i].label = res[i].id + " " + res[i].name;
          }

          this.userList = res;
        })
        .catch(() => {});
    },
    send() {
      console.log("this.details", this.details);
      this.$api
        .postAPI({
          _action: "/notification",
          _body: this.details,
          _buttonId: "save-button",
        })
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },
    countryFlag() {
      if (this.details.is_country == 1) {
        this.details.is_all = 0;
      }
      console.log("this.details.is_all", this.details.is_all);
      console.log("this.details.is_country", this.details.is_country);
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css">
</style>
<style scoped>
.multiselect {
  background: #f0f0f0;
  height: 55px;
  border-radius: 8px;
  border: 0;
}

.multiselect-tag {
  background: #9cc338 !important;
}
</style>