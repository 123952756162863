<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 my-auto">
              <h4 class="mb-0 lg-bld">View Rejected Product</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-12 col-sm-6">
                        <div class="form-group" style="margin-top: 10px">
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Product Front Image</label>
                              <span class="text-danger">*</span>
                              <div>
                                <img
                                  :src="'/img/profile.svg'"
                                  id="barcode"
                                  class="img-thumbnail"
                                  style="width: 80px; height: 80px"
                                /><br />
                                <div id="msg"></div>

                                <div
                                  id="image-form"
                                  style="margin: 0 auto; width: 90px"
                                >
                                  <input
                                    type="file"
                                    name="barcode_img"
                                    class="file"
                                    id="anyFile"
                                    @change="onChangeBarcode($event)"
                                    accept="image/*"
                                    style="display: none"
                                  />
                                  <div class="input-group my-1">
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="img-text"
                                      disabled
                                      placeholder="Upload File"
                                      id="file"
                                      style="display: none"
                                    />
                                    <div
                                      class="changePic"
                                      style="bottom: -25px; right: 130px"
                                    >
                                      <label for="anyFile"> Change</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group" style="margin-top: 25px">
                              <label>Product EAN Image</label>
                              <span class="text-danger">*</span>
                              <div>
                                <img
                                  :src="'/img/profile.svg'"
                                  id="barcode"
                                  class="img-thumbnail"
                                  style="width: 80px; height: 80px"
                                /><br />
                                <div id="msg"></div>

                                <div
                                  id="image-form"
                                  style="margin: 0 auto; width: 90px"
                                >
                                  <input
                                    type="file"
                                    name="barcode_img"
                                    class="file"
                                    id="anyFile"
                                    @change="onChangeBarcode($event)"
                                    accept="image/*"
                                    style="display: none"
                                  />
                                  <div class="input-group my-1">
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="img-text"
                                      disabled
                                      placeholder="Upload File"
                                      id="file"
                                      style="display: none"
                                    />
                                    <div
                                      class="changePic"
                                      style="bottom: -25px; right: 130px"
                                    >
                                      <label for="anyFile"> Change</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group" style="margin-top: 25px">
                              <label>Product Ingredient Image</label>
                              <span class="text-danger">*</span>
                              <div>
                                <img
                                  :src="'/img/profile.svg'"
                                  id="ingredient"
                                  class="img-thumbnail"
                                  style="width: 80px; height: 80px"
                                /><br />
                                <div id="msg"></div>

                                <div
                                  id="image-form"
                                  style="margin: 0 auto; width: 90px"
                                >
                                  <input
                                    type="file"
                                    name="ingredient_img"
                                    class="file"
                                    id="anyFile"
                                    @change="onChangeIngredient($event)"
                                    accept="image/*"
                                    style="display: none"
                                  />
                                  <div class="input-group my-1">
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="img-text"
                                      disabled
                                      placeholder="Upload File"
                                      id="file"
                                      style="display: none"
                                    />
                                    <div
                                      class="changePic"
                                      style="bottom: -25px; right: 130px"
                                    >
                                      <label for="anyFile"> Change</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-12col-sm-6">
                            <div class="form-group" style="margin-top: 25px">
                              <label>EAN code</label>
                              <span class="text-danger">*</span>
                              <input
                                type="text"
                                name="code"
                                class="form-control"
                                placeholder="Code"
                              />
                            </div>
                          </div>
                          <div class="col-lg-12 col-sm-12 text-center mt-2">
                            <button
                              type="button"
                              id="cancle-button"
                              class="fill-btn px-4 ml-3"
                              v-on:click="$router.go(-1)"
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ViewProduct",
  data() {},
  mounted() {},
  methods: {},
};
</script>
