<template>
  <div>
    <div class="wrapper">
      <Menu />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/menu";
export default {
  name: "MainPage",
  components: {
    Menu,
  },
  methods: {
    callMethod() {
      Menu.methods.getLoginUserDetail();
    },
  },
};
</script>

