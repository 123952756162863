<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/category')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ uuid ? "Edit" : "Add" }} Category</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div>
              <div class="row">
                <div class="col-lg-10 col-sm-10 float-none">
                  <div class="card border mb-0 radius-15">
                    <Form @submit="save">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-lg-12 col-sm-12 mb-3">
                            <div class="text-center mb-4">
                              <img
                                :src="
                                  details.image && details.image != null
                                    ? details.image
                                    : '/images/placeholder.gif'
                                "
                                id="preview"
                                class="img-thumbnail"
                                style="border-radius: 100px; width: 120px; height: 120px"
                              /><br />
                              <div id="msg"></div>
                              <div id="image-form" style="margin: 0 auto; width: 90px">
                                <input
                                  type="file"
                                  name="img[]"
                                  class="file"
                                  id="pickFile"
                                  v-on:change="onChange($event)"
                                  accept="image/*"
                                  style="display: none"
                                />
                                <div class="input-group my-1">
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled
                                    placeholder="Upload File"
                                    id="file"
                                    style="display: none"
                                  />
                                  <div class="changePic" style="bottom: -25px">
                                    <label for="pickFile"> Change</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label> Name</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="name"
                                class="form-control"
                                placeholder="Name"
                                v-model="details.name"
                                rules="required:name"
                                :validateOnInput="true"
                              />
                              <ErrorMessage name="name" class="text-danger" />
                            </div>
                          </div>

                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Order</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                class="form-control"
                                name="order"
                                v-model="details.order_id"
                                placeholder="Order"
                                rules="required|numeric:order"
                                :validateOnInput="true"
                              />
                              <ErrorMessage name="order" class="text-danger" />
                            </div>
                          </div>

                          <div class="col-lg-12 col-sm-6">
                            <div class="form-group">
                              <label>Description</label>
                              <span class="text-danger">*</span>
                              <Field
                                as="textarea"
                                type="text"
                                name="description"
                                class="form-control"
                                placeholder="Descripiton"
                                v-model="details.description"
                                rules="required:description"
                                :validateOnInput="true"
                              />
                              <ErrorMessage name="description" class="text-danger" />
                            </div>
                          </div>
                        </div>

                        <div class="row mt-3 mb-3">
                          <div class="col-lg-12 col-sm-12 text-center mt-2">
                            <button id="save-button" class="fill-btn px-4 ml-3">
                              Save
                            </button>
                            <button
                              type="button"
                              class="fil-btn px-4 ml-3"
                              v-on:click="back()"
                              id="cancel-button"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "AddCategory",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      uuid: this.$route.params.id,
      code: this.$route.params.code,
      details: {
        parent_id: this.$route.params.parent_id ? this.$route.params.parent_id : 0,
        is_featured: 0,
        top_menu: 0,
      },
      lang_code: "",
      currentTab: "DETAIL",
      currencyList: [],
    };
  },
  mounted() {
    if (this.uuid) {
      if (!this.code) {
        this.$router.go(-1);
      }
      this.lang_code = this.code;
      this.getDetail();
    } else {
      this.lang_code = "EN";
    }
    localStorage.setItem("lang", this.lang_code);
  },
  methods: {
    getDetail() {
      if (this.uuid) {
        this.$api
          .getAPI({
            _action: "/category/detail/admin/" + this.uuid,
            _body: { lang: this.lang_code },
          })
          .then((res) => {
            this.details = res;
          })
          .catch(() => {});
      }
    },
    save() {
      this.details.lang_code = this.lang_code;
      if (this.uuid) {
        this.details.uuid = this.uuid;
      }

      if (this.details.is_featured == 1) {
        this.details.is_featured = "Y";
      } else {
        this.details.is_featured = "N";
      }

      if (this.details.top_menu == 1) {
        this.details.top_menu = "Y";
      } else {
        this.details.top_menu = "N";
      }
      this.$api
        .postAPI({
          _action: "/category",
          _body: this.details,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (this.file) {
            this.uploadImage(res.category_id);
          } else {
            localStorage.setItem("lang", "EN");
            this.$router.go(-1);
          }
        })
        .catch(() => {});
    },
    back() {
      localStorage.setItem("lang", "EN");
      this.$router.go(-1);
    },
    onChange(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },

    uploadImage(id) {
      if (!this.file) {
        localStorage.setItem("lang", "EN");
        this.$router.go(-1);
      }
      this.$api
        .uploadImageAPI({
          _action: "/category/photo",
          _key: "image",
          _file: this.file,
          _body: {
            id: id,
          },
          _buttonId: "save-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.file = undefined;
          localStorage.setItem("lang", "EN");
          this.$router.go(-1);
        })
        .catch(() => {});
    },
  },
};
</script>
