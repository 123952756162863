<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/metaContent')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ uuid ? "Edit" : "Add" }} MetaContent</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div>
              <div class="row">
                <div class="col-lg-10 col-sm-10 float-none">
                  <div class="card border mb-0 radius-15">
                    <Form @submit="save">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label> Title</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="title"
                                class="form-control"
                                placeholder="Title"
                                v-model="details.title"
                                rules="required:title"
                                :validateOnInput="true"
                              />
                              <ErrorMessage name="title" class="text-danger" />
                            </div>
                          </div>

                          <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Code</label>
                              <span class="text-danger">*</span>
                              <Field
                                as="select"
                                class="form-control"
                                name="code"
                                v-model="details.code"
                                placeholder="Select Status"
                                rules="required:code"
                                :validateOnInput="true"
                                :disabled="lang_code != 'EN'"
                              >
                                <option value="" selected>Select Code</option>
                                <option value="Main Category">Main Category</option>
                                <option value="Category">Category</option>
                                <option value="Subcategory">SubCategory</option>
                                <option value="Community">Community</option>
                                <option value="Brand">Brand</option>
                                <option value="Product List">Product List</option>
                                <option value="Home">Home</option>
                                >
                                <option value="Profile">Profile</option>
                                >
                                <option value="Edit Profile">Edit Profile</option>
                                <option value="User Profile">User Profile</option>
                                <option value="About us">About us</option>
                                <option value="Terms & Conditions">
                                  Terms & Conditions
                                </option>
                                <option value="Privacy Policy">Privacy Policy</option>
                              </Field>
                              <ErrorMessage name="code" class="text-danger" />
                            </div>
                          </div>

                          <div class="col-lg-12 col-sm-6">
                            <div class="form-group">
                              <label>Description</label>
                              <span class="text-danger">*</span>
                              <Field
                                as="textarea"
                                type="text"
                                name="description"
                                class="form-control"
                                placeholder="Descripiton"
                                v-model="details.description"
                                rules="required:description"
                                :validateOnInput="true"
                              />
                              <ErrorMessage name="description" class="text-danger" />
                            </div>
                          </div>
                        </div>

                        <div class="row mt-3 mb-3">
                          <div class="col-lg-12 col-sm-12 text-center mt-2">
                            <button id="save-button" class="fill-btn px-4 ml-3">
                              Save
                            </button>
                            <button
                              type="button"
                              class="fil-btn px-4 ml-3"
                              v-on:click="
                                localStorage.setItem('lang', 'EN');
                                $router.go(-1);
                              "
                              id="cancel-button"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "AddMetaContent",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      uuid: this.$route.params.id,
      code: this.$route.params.code,
      details: {
        code: "",
      },
      lang_code: "",
      currentTab: "DETAIL",
      currencyList: [],
    };
  },
  mounted() {
    if (this.uuid) {
      if (!this.code) {
        this.$router.go(-1);
      }
      this.lang_code = this.code;
      this.getDetail();
    } else {
      this.lang_code = "EN";
    }
    localStorage.setItem("lang", this.lang_code);
  },
  methods: {
    getDetail() {
      if (this.uuid) {
        this.$api
          .getAPI({
            _action: "/metaContent/detail/" + this.uuid,
            _body: { lang: this.lang_code },
          })
          .then((res) => {
            this.details = res;
          })
          .catch(() => {});
      }
    },
    save() {
      this.details.lang_code = this.lang_code;
      if (this.uuid) {
        this.details.uuid = this.uuid;
      }
      this.$api
        .postAPI({
          _action: "/metaContent",
          _body: this.details,
          _buttonId: "save-button",
        })
        .then(() => {
          localStorage.setItem("lang", "EN");
          this.$router.go(-1);
        })
        .catch(() => {});
    },
  },
};
</script>
