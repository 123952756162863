<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto d-flex align-items-center mb-4">
            <a class="pr-2 text-dark cursor-pointer" v-on:click="$router.push('/role')"><span class="font-24 mr-2"><img
                  src="/images/back-arrow.svg" width="18" /></span>
            </a>
            <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Role</h4>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-4 col-sm-4 form-group row" style="margin-left: 3px">
                <label>Role Name</label>
                <input type="text" name="role_name" class="form-control" placeholder="Role Name" v-model="detail.name" />
              </div>
              <div class="col-lg-8 col-sm-4 form-group row" style="margin-left: 12px">
                <label>Description</label>
                <input type="text" name="description" class="form-control" placeholder="Description"
                  v-model="detail.description" />
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table class="table table-striped table-bordered dataTable" style="width: 100%" role="grid"
                      aria-describedby="example_info">
                      <thead>
                        <tr>
                          <th>Access to</th>
                          <!-- <th>All</th> -->
                          <th>View</th>
                          <th>Add</th>
                          <th>Edit</th>
                          <th>Delete</th>
                          <th>Export</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in detail.access" :key="index">
                          <td>{{ data.name }}</td>
                          <!-- <td><input type="checkbox"  v-model="data.is_all" true-value="1" false-value="0"/></td> -->
                          <td>
                            <input type="checkbox" v-model="data.is_view" true-value="1" false-value="0" />
                          </td>
                          <td>
                            <input type="checkbox" v-model="data.is_add" true-value="1" false-value="0" />
                          </td>
                          <td>
                            <input type="checkbox" v-model="data.is_edit" true-value="1" false-value="0" />
                          </td>
                          <td>
                            <input type="checkbox" v-model="data.is_delete" true-value="1" false-value="0" />
                          </td>
                          <td>
                            <input type="checkbox" v-model="data.is_export" true-value="1" false-value="0" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-lg-12 col-sm-12 text-center mt-2">
                    <button id="save-button" class="fill-btn px-4 ml-3" v-on:click="save">
                      Save
                    </button>
                    <button type="button" class="fil-btn px-4 ml-3" v-on:click="$router.go(-1)" id="cancel-button">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AddRole",
  data() {
    return {
      id: this.$route.params.id,
      itemsPerPage: 10, searchObj: {
        keyword: "",
      },
      detail: {
        name: "",
        access: [
          {
            name: "Dashboard",
            url: "dashboard",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0, is_export: 0,
          },
          {
            name: "Statistics",
            url: "statistics",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0, is_export: 0,
          },
          {
            name: "Manage Banners",
            url: "banner",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0, is_export: 0,
          },
          {
            name: "Notifications",
            url: "manage-notification",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0, is_export: 0,
          },
          {
            name: "Manage Customers",
            url: "customer",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0, is_export: 0,
          },
          {
            name: "Manage Products",
            url: "product",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0, is_export: 0,
          },
          // {
          //   name: "All Products",
          //   url: "product",
          //   is_all: 0,
          //   is_view: 0,
          //   is_add: 0,
          //   is_edit: 0,
          //   is_delete: 0,is_export: 0,
          // },
          {
            name: "Requested Products",
            url: "requested",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0, is_export: 0,
          },
          {
            name: "Manage Categories",
            url: "category",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0, is_export: 0,
          },
          {
            name: "Manage Brands",
            url: "brand",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0, is_export: 0,
          },
          {
            name: "Manage Contents",
            url: "content",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0, is_export: 0,
          },
          {
            name: "Manage Admins",
            url: "admin",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0, is_export: 0,
          },
          {
            name: "Manage Roles",
            url: "role",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0, is_export: 0,
          },
          {
            name: "Send Notifications",
            url: "snd-notification",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0, is_export: 0,
          },
          {
            name: "Manage Reviews",
            url: "all-review",
            is_all: 0,
            is_view: 0,
            is_add: 0,
            is_edit: 0,
            is_delete: 0, is_export: 0,
          },
        ],
      },
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "/role/detail/" + this.id,
        })
        .then((res) => {
          this.detail = res;
        })
        .catch(() => { });
    },
    save() {
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/role",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => { });
      } else {
        this.$api
          .postAPI({
            _action: "/role",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => { });
      }
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
  },
};
</script>

