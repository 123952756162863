<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Contents</h4>
          </div>
        </div>
        <div class="page-content" v-show="accessObject?.is_view==1 || !isShowAccessDeniedMessage">
          <div class="card-body py-0 px-0">
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th>Content Name</th>
                          <th v-show="accessObject?.is_edit == 1">Action</th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr v-for="(data, index) in list" :key="index">
                          <td style="text-align:left;">{{ index + 1 }}</td>
                          <td style="text-align:left;">{{ $helperService.getTitleCase(data.title)}}</td>
                          <td v-show="accessObject?.is_edit == 1">
                          <a class="fill-btn btn-style cursor-pointer" v-on:click="$router.push('/edit-content?type='+data.title)" v-show="accessObject?.is_edit==1">Edit</a>&nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         <div
          class="text-center"
          style="margin-top: 20%"
          v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage"
        >
          <h3>You do not have access to view content</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
        name: "ContentPage",
        data() {
            return {
                accessObject: this.$helperService.getAccessLevel("Manage Contents"),
                isShowAccessDeniedMessage: false,
                type: this.$route.query.type,
                offset: -1,
                list: [],
            };
        },
        mounted() {
           if (!this.accessObject) {
                this.isShowAccessDeniedMessage = true;
                this.$router.push("/access-denied");
                return;
            }
            this.getList();
        },
        methods: {
            getList() {
                this.$api
                    .getAPI({
                        _action: "/cms/list",
                    })
                    .then((res) => {
                        this.list = res;
                    })
                    .catch(() => {});
            },

        },
    };
</script>

