<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 my-auto">
              <h4 class="mb-0 lg-bld">View Rejected Product</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                          <label>Product Front Image</label>
                          <input type="text" name="" class="form-control" placeholder="Please enter product front image"/>
                        </div>
                      </div>
                       <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                          <label>Product EAN Image</label>
                          <input type="text" name="" class="form-control" placeholder="Please enter product EAN image"/>
                        </div>
                      </div>
                      <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                          <label> EAN Code</label>
                          <input type="text" name="" class="form-control" placeholder="Please enter  EAN code"/>
                        </div>
                      </div>
                      
                      
                      
                     
                        
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button
                            type="button"
                            id="save-button"
                            class="fill-btn px-4 ml-3">
                            Back
                          </button>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
 
</template>
<script>
export default {
  name: "RejectedProduct",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>